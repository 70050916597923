.patient-position-column .series-description .ui.input {
    padding: 0 !important;
    max-width: 184px !important;
    margin-right: 7px !important;
    margin-bottom: 4px !important;
    height: 25px !important;
}

.series-description .ui.input input {
    background: #dddddd;
    border-radius: 0 !important;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    -ms-border-radius: 0 !important;
    -o-border-radius: 0 !important;
    width: 185px !important;
    height: 20px !important;
}

.series-description .ui.input input:hover {
    cursor: default;
}

.series-description .ui.input input:focus {
    background: #dddddd;
}

.patient-selection-field .ui.selection {
    font-weight: bold;
    background: linear-gradient(to top, #b1bbc0 1%, #dfe5e7 100%)
}

.patient-selection-field .ui.selection.dropdown {
    box-shadow: 0px 0.5px 0.5px #525151 !important;
    padding-left: 8px !important;
}

.patient-selection-field .ui.selection:hover {
    background: linear-gradient(to top,#dfe5e7 1%, #ebf1f3 100%);
    border: 1px solid #2caeff;
    cursor: default;
}

.patient-selection-field .ui.selection.dropdown .item {
    height: 22px !important;
    line-height: 0px !important;
    font-size: 11px !important;
    padding-left: 8px !important;
    border-top: none !important;
}

.patient-selection-field .ui.selection.dropdown .item:hover {
    background: #b2e1ff !important;
    cursor: default !important;
}

.patient-selection-field .ui.selection.dropdown>.dropdown.icon {
    padding: 0 !important;
    padding-right: 3px !important;
    padding-left: 5px !important;
    margin-top: -9px !important;
    font-size: 14px !important;
    border-left: 1px solid rgba(23, 50, 59, 0.2) !important;
}

.patient-selection-field .ui.selection.dropdown>.dropdown.icon:hover {
    cursor: default
}

.patient-selection-field .ui.selection.dropdown:hover>.dropdown.icon {
    border-left: 1px solid #2caeff !important;
}

.patient-selection-field .ui.selection.dropdown .active.selected.item {
    font-weight: 400 !important;
    background: #7fceff !important;
}

.imaging-plane-mode-group {
    margin-bottom: 5px !important;
    width: 300px !important;
    display: flex;
    padding-left: 50px;
}

.imaging-plane-mode-group .plane-field input {
    width: 54px !important;
    height: 22px !important;
    display: inline-block;
    border-radius: 0 !important;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    -ms-border-radius: 0 !important;
    -o-border-radius: 0 !important;
}

.imaging-plane-mode-group .plane-field span {
    height: 22px;
    line-height: 22px;
    padding-right: 27px;
    margin-left: -30px;
    font-weight: 400;
    color: #1b4049 !important;
    font-size: 9px !important;
}

.imaging-plane-mode-group .mode-field input {
    width: 43px !important;
    height: 22px !important;
    display: inline-block;
    border-radius: 0 !important;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    -ms-border-radius: 0 !important;
    -o-border-radius: 0 !important;
}

.imaging-plane-mode-group .mode-field span {
    height: 22px;
    line-height: 22px;
    margin-left: 10px;
    margin-right: 10px;
    font-weight: 400;
    color: #1b4049 !important;
    font-size: 9px !important;
}

.patient-position-column .pulse-field .ui.input {
    height: 22px !important;
    padding-bottom: 0 !important;
    padding-top: 0 !important;
    margin-bottom: 5px !important;
}

.pulse-field input {
    border-radius: 0 !important;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    -ms-border-radius: 0 !important;
    -o-border-radius: 0 !important;
}

.imaging-options-group {
    margin-bottom: 6px !important;
    margin-left: 15px !important;
}

.imaging-options-field {
    width: 205px;
    height: 45px;
    padding: 0 3px 0 0 !important;
}

.imaging-options-group .field label {
    width: 45px !important;
    flex: 0;
}

.imaging-options-field textarea {
    margin-left: 22px !important;
    height: 45px;
    width: 150px !important;
    padding: 2px 3px !important;
    resize: none !important;
    border-radius: 0 !important;
    color: #1b4049 !important;
    font-size: 10px !important;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    -ms-border-radius: 0 !important;
    -o-border-radius: 0 !important;
}

.imaging-options-button {
    margin-left: 4px !important;
    margin-top: 12px;
    width: 30px;
    height: 22px;
    border-top: 1px solid lightgrey !important;
    border-bottom: 1px solid grey !important;
    border-left: 1px solid grey !important;
    border-right: 1px solid grey !important;
    font-weight: bold;
    background: linear-gradient(to top, #e7e6e6 1%, #ffffff 100%) !important;
    border-radius: .28571429rem;
    -webkit-border-radius: .28571429rem;
    -moz-border-radius: .28571429rem;
    -ms-border-radius: .28571429rem;
    -o-border-radius: .28571429rem;
}

.imaging-options-button:hover {
    background: linear-gradient(to top, #e7e6e6 1%, #ffffff 70%) !important;
    border: 1px solid #2caeff !important;
    cursor: default !important;
}

.imaging-options-modal {
    width: 193px !important;
    height: 428px !important;
}

.imaging-options-modal.ui.modal .close.icon {
    top: -1px !important;
    right: -5px !important;
    font-size: 13px;
}

.imaging-options-modal .header {
    border-bottom: none !important;
    padding: 5px 0 0 10px !important;
    font-size: 12px !important;
    text-transform: none;
    color: rgba(23, 50, 59, 1) !important;
    background: #e2e2e2 !important;
}

.imaging-options-modal .content {
    text-align: left !important;
    padding: 0 !important;
    height: 408px;
    border: 10px solid #e2e2e2 !important;
}

.imaging-options-list {
    list-style: none;
    padding-left: 10px;
    padding-top: 5px;
    line-height: 29px;
    margin: 0;
}

.imaging-options-list li [type=checkbox] {
    position: relative;
    top: 3px;
}

.imaging-options-list li label {
    margin-bottom: 0 !important;
}

.imaging-options-list li span {
    margin-left: 5px;
    font-size: 11px;
    font-weight: 400;
}

.imaging-options-modal hr {
    margin-top: 0;
    margin-left: 10px;
    width: 140px;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.3);
}

.imaging-select-all {
    padding-left: 10px;
}

.imaging-select-all [type=checkbox]  {
    position: relative;
    top: 3px;
}

.imaging-select-all span {
    margin-left: 5px;
    font-size: 11px;
    font-weight: 400;
}

.imaging-options-modal-buttons {
    width: 130px;
    text-align: center;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    padding-top: 4px;
}

.imaging-options-modal-buttons button {
    color: rgba(23, 50, 59, 0.9) !important;
    font-size: 12px;
    font-weight: 700;
    padding: 4px;
    width: 60px;
    background: linear-gradient(to top, #e7e6e6 1%, #ffffff 100%) !important;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.imaging-options-modal-buttons button:hover {
    background: linear-gradient(to top, #e7e6e6 1%, #ffffff 70%) !important;
    border: 1px solid #2caeff !important;
    cursor: default !important;
}