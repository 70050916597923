.recon-modal {
    background: #8197a2 !important;
    height: 550px !important;
    width: 550px !important;
    padding: 0 10px 10px 10px !important;
}

.recon-modal .header {
    padding: 5px 0 !important;
    border-bottom: none !important;
    background: #8197a2 !important;
    color: #04161a !important;
    font-size: 12px !important;
}

.recon-modal .close.icon {
    margin: 0 !important;
    padding: 5px 0 0 0 !important;
    font-size: 14px !important;
}

.recon-modal .close.icon:hover {
    cursor: default !important;
}

.recon-modal .content {
    /* overflow: scroll !important; */
    padding: 0 !important;
    background: #95abb6 !important;
    max-height: 515px !important;
    max-width: 530px !important;
    padding-top: 5px !important;
}

.recon-modal-content-header {
    text-align: center;
    padding-top: 0px !important;
    /* padding-left: 10px !important; */
    margin: 0 auto 10px auto !important;
    width: 540px !important;
    color: #f6f6e0;
    text-transform: uppercase;
    font-size: 15px !important;
    font-family: Verdana, Geneva, Tahoma, sans-serif !important;
    font-weight: bold !important;
}

.recon-image-container {
    width: 400px;
    margin: 0 auto;
}

.recon-image-container .carousel__slider {
    background: black;
}

#recon-prior-button, #recon-next-button {
    height: 35px !important;
    width: 80px !important;
    font-size: 18px !important;
}

#recon-dropdown {
    height: 35px !important;
    width: 80px !important;
    font-size: 16px !important;
    line-height: 30px !important;
    position: relative;
    z-index: 20;
}

#recon-dropdown .item {
    height: 35px !important;
    line-height: 30px !important;
    font-size: 16px !important;
    position: relative;
    z-index: 20;
    /* z-index: 10; */
}

#recon-dropdown>.dropdown.icon {
    height: 30px !important;
    padding-left: 12px !important;
    padding-right: 10px !important;
    padding-top: 3px !important;
    font-size: 18px !important;
    margin-top: -13px !important;
}

.recon-navigation-bottom {
    padding-top: 10px;
    padding-bottom: 5px;
    /* padding-left: 35px; */
    display: flex;
    width: 100%;
    justify-content: center;
}

.recon-navigation-bottom > button {
    height: 22px;
    width: 60px;
    font-size: 12px !important;
    margin: 0 5px !important;
    padding: 0 !important;
    border-radius: 5px !important;
    -webkit-border-radius: 5px !important;
    -moz-border-radius: 5px !important;
    -ms-border-radius: 5px !important;
    -o-border-radius: 5px !important;
    border-top: 1px solid lightgrey !important;
    border-bottom: 1px solid grey !important;
    border-left: 1px solid grey !important;
    border-right: 1px solid grey !important;
    background: linear-gradient(to top, #b1bbc0 1%, #dfe5e7 100%) !important;
    color: rgba(23, 50, 59, 0.9) !important;
    font-weight: 700 !important;
    line-height: 20px !important;
}

.recon-navigation-bottom > button:hover {
    background: linear-gradient(to top,#dfe5e7 1%, #ebf1f3 100%) !important;  
    border: 1px solid #2caeff !important;
    cursor: default !important;
}

.recon-navigation-bottom > button:disabled {
    background: linear-gradient(to top, #b1bbc0 1%, #dfe5e7 100%) !important;
    color: grey !important;
    border-top: 1px solid lightgrey !important;
    border-bottom: 1px solid grey !important;
    border-left: 1px solid grey !important;
    border-right: 1px solid grey !important;
}

.recon-navigation-bottom .draw-button {
    width: 90px;
}

.recon-canvas-container {
    position: absolute;
    z-index: 2;
}


.error-modal-message {
    width: 315px !important;
    height: 100px !important;
    padding: 20px 10px 10px 10px !important;
    background: rgba(124, 143, 153, 0.8) !important;
}

.error-modal-message .content {
    width: 100% !important;
    height: 100% !important;
    text-align: center;
    border: 1px solid #dddddd !important;
    padding: 10px 0 0 0 !important;
    border-radius: 0 !important;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    -ms-border-radius: 0 !important;
    -o-border-radius: 0 !important;
    background: #dddddd !important;
}

.error-modal-message p {
    font-size: 11px !important;
}

.error-modal-message button {
    margin-top: 3px;
    height: 22px !important;
    width: 65px !important;
    font-size: 11px;
    font-weight: bold;
    background: linear-gradient(to top, #cecece 1%, #ffffff 100%) !important;
    border: 2px solid grey;
    border-radius: .28571429rem;
    -webkit-border-radius: .28571429rem;
    -moz-border-radius: .28571429rem;
    -ms-border-radius: .28571429rem;
    -o-border-radius: .28571429rem;
}

.error-modal-message button:hover {
    background: white !important;
    border: 1px solid grey
}