body {
  overflow: hidden;
  background: #98a9b1 !important;
  font-size: 9px !important;
  line-height: 1em !important;
  display: flex; 
  flex-direction: column;
  justify-content: center;
}

:focus {
  outline: none !important;
}

.dimmed.dimmable > :not(.dimmer) {
  -webkit-filter: blur(1px);
  filter: blur(1px);
}

.dimmed.dimmable > .tooltip-mri {
  filter: blur(0px) !important;
  -webkit-filter: blur(0px) !important;
}

h4 {
  margin-top: 0 !important;
}

.ui.celled.grid>.row>.column {
  padding: 0 1em !important;
}

.home-page .ui .header {
  color: lightgoldenrodyellow !important;
  text-transform: uppercase;
  font-weight: 600;
}

.home-page .ui .table .header {
  color: black !important;
}
.modal .header {
  color: gray !important;
}

.dimmer {
  background-color: rgba(221,221,221, 0.5) !important;
  transition: none !important;
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -ms-transition: none !important;
  -o-transition: none !important;
  animation: none !important;
  -webkit-animation: none !important;
}

.equal.fields .field {
  width: 100px !important;
}
.equal.fields>.field input {
  width: 100% !important;
}

.ui.form .field {
  margin: 0 !important;
}

.ui.center.aligned.header {
  margin: 0 !important;
}

.field {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 5px 0;
}

.field .ui.dropdown {
  width: 100%;
  margin: 0 7px 5px 7px;
  flex: 1 1 75%;
  height: 31px;
}

.home-page {
  width: 948px !important;
  /* align-self: center; */
}

.home-page .field .ui.input {
  width: 100%;
  margin: 0 5px;
  flex: 1 1 75%;
  height: 27px;
  padding: 2px !important;
}

.home-page .field .input input {
  width: 100% !important;
}

.field label {
  display: block;
  flex: 1 1 25%;
}

label {
  text-align: left;
  color: #3d494d !important;
  display: block;
  margin: 0 0 .28571429rem 0;
  font-size: .9em;
  font-weight: 700;
  text-transform: none;
}

.ui.modal .close.icon {
  top: 0 !important;
  right: 0 !important;
  color: #000000 !important;
}

.modal-scan-header .header {
  padding: none !important;
}
.tooltip-mri {
    padding: 5px !important;
    font-size: 12px !important;
    background: #f8f9c9 !important;
    border-color: #f8f9c9 !important;
}

.tooltip-mri::before {
    display: none !important;
}
.coil-selection > .field {
    width: 214px !important;
    margin: 0 !important;
    padding-left: 32px !important;
  }
  
.coil-selection .field .ui.input {
  padding: 0 !important;
  height: 27px;
}
  
.coil-selection {
  width: 100% !important;
  display: inline-flex !important;
  margin: 0 !important;
}

.coil-selection .ui.input {
  margin: 0 !important;
  padding: 0 !important;
}

.coil-selection .field .input input {
  width: 147px !important;
  flex-grow: 0 !important;
  margin-left: 29px;
  border-radius: 0 !important;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  -ms-border-radius: 0 !important;
  -o-border-radius: 0 !important;
}

.coil-trigger-button {
  margin-left: 9px;
  margin-top: 2px;
  display: inline-block;
  width: 30px;
  height: 22px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  font-size: 12px;
  font-weight: 700;
  color: rgba(23, 50, 59, 0.9) !important;
  border-top: 1px solid lightgrey !important;
  border-bottom: 1px solid grey !important;
  border-left: 1px solid grey !important;
  border-right: 1px solid grey !important;
  background: -webkit-linear-gradient(bottom, #e7e6e6 1%, #ffffff 100%) !important;
  background: linear-gradient(to top, #e7e6e6 1%, #ffffff 100%) !important;
}

.coil-trigger-button:hover {
  background: -webkit-linear-gradient(bottom, #e7e6e6 1%, #ffffff 70%) !important;
  background: linear-gradient(to top, #e7e6e6 1%, #ffffff 70%) !important;
  border: 1px solid #2caeff !important;
  cursor: default !important;
}

.coils-modal {
  width: 650px !important;
  height: 500px !important;
  background: #e2e2e2 !important;
  padding: 5px 10px 10px 10px !important;
  /* iOS Safari fix for positioning: */
  position: relative !important;
  /* ------------------------------- */
}

.coils-modal .header {
  padding: 0 !important;
  border-bottom: none !important;
  font-size: 12px !important;
  color: #1b4049 !important;
  background: #e2e2e2 !important;
  margin-bottom: 10px !important;
}

.coils-modal .content {
  padding: 0 !important;
  text-align: center;
  height: 460px;
  overflow-y: auto; 
}

.coils-modal .content p {
  font-size: 12px !important;
  font-weight: bold;
  padding-top: 5px !important;
  margin-bottom: 0em !important;
}

.coil-image {
  height: 185px !important;
}

.coils-modal .close.icon {
  padding: 8px 0 0 0 !important;
  margin: 0 !important;
  font-size: 14px !important;
}

.coils-modal .close.icon:hover {
  cursor: default !important;
}

.coils-each-column {
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin: 5px !important;
  width: 195px !important;
}

.coils-each-column span {
  font-weight: bold;
}
.human-body-image {
  max-width: 140px !important;
  height: 270px !important;
}

#back-to-position-button {
  top: 262px;
  left: 175px;
  height: 24px;
  width: 75px !important;
  font-size: 11px;
  border-radius: 5px;
  border-top: 1px solid lightgrey !important;
  border-bottom: 1px solid grey !important;
  border-left: 1px solid grey !important;
  border-right: 1px solid grey !important;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  background: -webkit-linear-gradient(bottom, #e7e6e6 1%, #ffffff 100%) !important;
  background: linear-gradient(to top, #e7e6e6 1%, #ffffff 100%) !important;
  font-weight: bold !important;
  color: #17323b !important;
}

#back-to-position-button:hover {
  background: -webkit-linear-gradient(bottom, #e7e6e6 1%, #ffffff 70%) !important;
  background: linear-gradient(to top, #e7e6e6 1%, #ffffff 70%) !important;
  border: 1px solid #2caeff !important;
  cursor: default !important;
}

.human-body-parts button {
  background: none !important;
  font-size: 12px !important;
  position: absolute;
  left: 144px;
  width: 110px !important;
  color: #17323b !important;
  padding: 5px !important;
}

.human-body-parts button:hover {
  background: #b2e1ff !important;
}

.head-button {
  top: 22px;
}

.neck-button {
  top: 48px;
}

.chest-button {
  top: 69px;
}

.upper-extremity-button {
  top: 89px;
}

.abdomen-button {
  top: 108px;
}

.pelvis-button {
  top: 138px;
}

.lower-extremity-button {
  top: 200px;
}

.protocol-selection > .field {
  width: 185px !important;
  margin: 0 0 0 10px !important;
}

.protocol-selection .field .ui.input {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 15px !important;
  height: 30px;
  margin: 0 !important;
  flex: 0 1;
}

.protocol-selection {
  width: 260px !important;
  display: inline-flex !important;
  margin: 0 !important;
}

.protocol-selection .field .input input {
  width: 99px !important;
  flex-grow: 0 !important;
  border-radius: 0 !important;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  -ms-border-radius: 0 !important;
  -o-border-radius: 0 !important;
}

.popup-trigger-button {
  display: inline-block;
  margin-right: 6px !important;
  margin-left: 4px !important;
  min-width: 80px;
  height: 22px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  font-size: 12px;
  font-weight: 700;
  color: rgba(23, 50, 59, 0.9) !important;
  border-top: 1px solid lightgrey !important;
  border-bottom: 1px solid grey !important;
  border-left: 1px solid grey !important;
  border-right: 1px solid grey !important;
  background: -webkit-linear-gradient(bottom, #e7e6e6 1%, #ffffff 100%) !important;
  background: linear-gradient(to top, #e7e6e6 1%, #ffffff 100%) !important;
}

.popup-trigger-button:hover {
  background: -webkit-linear-gradient(bottom, #e7e6e6 1%, #ffffff 70%) !important;
  background: linear-gradient(to top, #e7e6e6 1%, #ffffff 70%) !important;
  border: 1px solid #2caeff !important;
  cursor: default !important;
}

.protocol-selection-modal {
  min-width: 170px !important;
  min-height: 300px !important;
  width: auto !important;
  border-left: 10px solid #e2e2e2 !important;
  border-right: 10px solid #e2e2e2 !important;
  border-bottom: 10px solid #e2e2e2 !important;
  top: 25px;
  right: 480px;
}

@media (min-height: 701px) {
  .protocol-selection-modal {
    top: 110px;
  }
}

.protocol-selection-modal .header {
  padding: 0 !important;
  font-size: 12px !important;
  text-align: center;
  text-transform: none !important;
  color: #17323b !important;
  border-bottom: none !important;
  background: #e2e2e2 !important;
  padding-top: 10px !important;
  border-radius: 0 !important;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  -ms-border-radius: 0 !important;
  -o-border-radius: 0 !important;
}

.protocol-selection-modal.ui.modal .close.icon {
  top: 1px !important;
  right: -12px !important;
  font-size: 1em !important;
}

.protocol-selection-modal .content {
  padding: 10px !important;
  border-top: 10px solid #e2e2e2;
}

.protocol-list {
  border: 1px solid rgba(0,0,0,0.2);
  min-height: 235px;
}

.protocol-list ul {
  list-style: none;
  padding: 0;
  margin: 0;
  text-indent: 5px;
}

.protocol-list li {
  font-size: 11px;
  margin-bottom: 5px;
  color: #1b4049;
}

.protocol-list li:hover {
  background: #b2e1ff;
  cursor: default;
}

.protocol-error-message {
  width: auto !important;
  height: 95px !important;
  padding: 20px 10px 10px 10px !important;
  background: rgba(124, 143, 153, 0.8) !important;
}

.protocol-error-message .content {
  width: 100% !important;
  height: 100% !important;
  text-align: center;
  border: 1px solid #dddddd !important;
  padding: 10px 20px 0 20px !important;
  border-radius: 0 !important;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  -ms-border-radius: 0 !important;
  -o-border-radius: 0 !important;
  background: #dddddd !important;
}

.protocol-error-message .content p {
  font-size: 11px !important;
}

.protocol-error-message .content button {
  height: 22px !important;
  width: 65px !important;
  font-size: 11px;
  font-weight: bold;
  background: -webkit-linear-gradient(bottom, #cecece 1%, #ffffff 100%) !important;
  background: linear-gradient(to top, #cecece 1%, #ffffff 100%) !important;
  border: 2px solid grey;
  border-radius: .28571429rem;
  -webkit-border-radius: .28571429rem;
  -moz-border-radius: .28571429rem;
  -ms-border-radius: .28571429rem;
  -o-border-radius: .28571429rem;
}

.protocol-error-message .content button:hover {
  background: white !important;
  border: 1px solid grey
}

.protocol-confirm-message {
  width: 195px !important;
  height: 95px !important;
  padding: 20px 10px 10px 10px !important;
  background: rgba(124, 143, 153, 0.8) !important;
}

.protocol-confirm-message .content {
  width: 100% !important;
  height: 100% !important;
  text-align: center;
  border: 1px solid #dddddd !important;
  padding: 10px 0 0 0 !important;
  border-radius: 0 !important;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  -ms-border-radius: 0 !important;
  -o-border-radius: 0 !important;
  background: #dddddd !important;
}

.protocol-confirm-message p {
  font-size: 11px !important;
}

.protocol-confirm-message button {
  height: 22px !important;
  width: 65px !important;
  font-size: 11px;
  font-weight: bold;
  background: -webkit-linear-gradient(bottom, #cecece 1%, #ffffff 100%) !important;
  background: linear-gradient(to top, #cecece 1%, #ffffff 100%) !important;
  border: 2px solid grey;
  border-radius: .28571429rem;
  -webkit-border-radius: .28571429rem;
  -moz-border-radius: .28571429rem;
  -ms-border-radius: .28571429rem;
  -o-border-radius: .28571429rem;
}

.protocol-confirm-message button:hover {
  background: white !important;
  border: 1px solid grey
}

.body-part-confirm-message {
  width: 275px !important;
  height: 95px !important;
  padding: 20px 10px 10px 10px !important;
  background: rgba(124, 143, 153, 0.8) !important;
}

.body-part-confirm-message .content {
  width: 100% !important;
  height: 100% !important;
  text-align: center;
  border: 1px solid #dddddd !important;
  padding: 10px 0 0 0 !important;
  border-radius: 0 !important;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  -ms-border-radius: 0 !important;
  -o-border-radius: 0 !important;
  background: #dddddd !important;
}

.body-part-confirm-message p {
  font-size: 11px !important;
}

.body-part-confirm-message button {
  margin-top: 3px;
  height: 22px !important;
  width: 65px !important;
  font-size: 11px;
  font-weight: bold;
  background: -webkit-linear-gradient(bottom, #cecece 1%, #ffffff 100%) !important;
  background: linear-gradient(to top, #cecece 1%, #ffffff 100%) !important;
  border: 2px solid grey;
  border-radius: .28571429rem;
  -webkit-border-radius: .28571429rem;
  -moz-border-radius: .28571429rem;
  -ms-border-radius: .28571429rem;
  -o-border-radius: .28571429rem;
}

.body-part-confirm-message button:hover {
  background: white !important;
  border: 1px solid grey
}
.patient-position-column .series-description .ui.input {
    padding: 0 !important;
    max-width: 184px !important;
    margin-right: 7px !important;
    margin-bottom: 4px !important;
    height: 25px !important;
}

.series-description .ui.input input {
    background: #dddddd;
    border-radius: 0 !important;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    -ms-border-radius: 0 !important;
    -o-border-radius: 0 !important;
    width: 185px !important;
    height: 20px !important;
}

.series-description .ui.input input:hover {
    cursor: default;
}

.series-description .ui.input input:focus {
    background: #dddddd;
}

.patient-selection-field .ui.selection {
    font-weight: bold;
    background: -webkit-linear-gradient(bottom, #b1bbc0 1%, #dfe5e7 100%);
    background: linear-gradient(to top, #b1bbc0 1%, #dfe5e7 100%)
}

.patient-selection-field .ui.selection.dropdown {
    box-shadow: 0px 0.5px 0.5px #525151 !important;
    padding-left: 8px !important;
}

.patient-selection-field .ui.selection:hover {
    background: -webkit-linear-gradient(bottom,#dfe5e7 1%, #ebf1f3 100%);
    background: linear-gradient(to top,#dfe5e7 1%, #ebf1f3 100%);
    border: 1px solid #2caeff;
    cursor: default;
}

.patient-selection-field .ui.selection.dropdown .item {
    height: 22px !important;
    line-height: 0px !important;
    font-size: 11px !important;
    padding-left: 8px !important;
    border-top: none !important;
}

.patient-selection-field .ui.selection.dropdown .item:hover {
    background: #b2e1ff !important;
    cursor: default !important;
}

.patient-selection-field .ui.selection.dropdown>.dropdown.icon {
    padding: 0 !important;
    padding-right: 3px !important;
    padding-left: 5px !important;
    margin-top: -9px !important;
    font-size: 14px !important;
    border-left: 1px solid rgba(23, 50, 59, 0.2) !important;
}

.patient-selection-field .ui.selection.dropdown>.dropdown.icon:hover {
    cursor: default
}

.patient-selection-field .ui.selection.dropdown:hover>.dropdown.icon {
    border-left: 1px solid #2caeff !important;
}

.patient-selection-field .ui.selection.dropdown .active.selected.item {
    font-weight: 400 !important;
    background: #7fceff !important;
}

.imaging-plane-mode-group {
    margin-bottom: 5px !important;
    width: 300px !important;
    display: flex;
    padding-left: 50px;
}

.imaging-plane-mode-group .plane-field input {
    width: 54px !important;
    height: 22px !important;
    display: inline-block;
    border-radius: 0 !important;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    -ms-border-radius: 0 !important;
    -o-border-radius: 0 !important;
}

.imaging-plane-mode-group .plane-field span {
    height: 22px;
    line-height: 22px;
    padding-right: 27px;
    margin-left: -30px;
    font-weight: 400;
    color: #1b4049 !important;
    font-size: 9px !important;
}

.imaging-plane-mode-group .mode-field input {
    width: 43px !important;
    height: 22px !important;
    display: inline-block;
    border-radius: 0 !important;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    -ms-border-radius: 0 !important;
    -o-border-radius: 0 !important;
}

.imaging-plane-mode-group .mode-field span {
    height: 22px;
    line-height: 22px;
    margin-left: 10px;
    margin-right: 10px;
    font-weight: 400;
    color: #1b4049 !important;
    font-size: 9px !important;
}

.patient-position-column .pulse-field .ui.input {
    height: 22px !important;
    padding-bottom: 0 !important;
    padding-top: 0 !important;
    margin-bottom: 5px !important;
}

.pulse-field input {
    border-radius: 0 !important;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    -ms-border-radius: 0 !important;
    -o-border-radius: 0 !important;
}

.imaging-options-group {
    margin-bottom: 6px !important;
    margin-left: 15px !important;
}

.imaging-options-field {
    width: 205px;
    height: 45px;
    padding: 0 3px 0 0 !important;
}

.imaging-options-group .field label {
    width: 45px !important;
    flex: 0 1;
}

.imaging-options-field textarea {
    margin-left: 22px !important;
    height: 45px;
    width: 150px !important;
    padding: 2px 3px !important;
    resize: none !important;
    border-radius: 0 !important;
    color: #1b4049 !important;
    font-size: 10px !important;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    -ms-border-radius: 0 !important;
    -o-border-radius: 0 !important;
}

.imaging-options-button {
    margin-left: 4px !important;
    margin-top: 12px;
    width: 30px;
    height: 22px;
    border-top: 1px solid lightgrey !important;
    border-bottom: 1px solid grey !important;
    border-left: 1px solid grey !important;
    border-right: 1px solid grey !important;
    font-weight: bold;
    background: -webkit-linear-gradient(bottom, #e7e6e6 1%, #ffffff 100%) !important;
    background: linear-gradient(to top, #e7e6e6 1%, #ffffff 100%) !important;
    border-radius: .28571429rem;
    -webkit-border-radius: .28571429rem;
    -moz-border-radius: .28571429rem;
    -ms-border-radius: .28571429rem;
    -o-border-radius: .28571429rem;
}

.imaging-options-button:hover {
    background: -webkit-linear-gradient(bottom, #e7e6e6 1%, #ffffff 70%) !important;
    background: linear-gradient(to top, #e7e6e6 1%, #ffffff 70%) !important;
    border: 1px solid #2caeff !important;
    cursor: default !important;
}

.imaging-options-modal {
    width: 193px !important;
    height: 428px !important;
}

.imaging-options-modal.ui.modal .close.icon {
    top: -1px !important;
    right: -5px !important;
    font-size: 13px;
}

.imaging-options-modal .header {
    border-bottom: none !important;
    padding: 5px 0 0 10px !important;
    font-size: 12px !important;
    text-transform: none;
    color: rgba(23, 50, 59, 1) !important;
    background: #e2e2e2 !important;
}

.imaging-options-modal .content {
    text-align: left !important;
    padding: 0 !important;
    height: 408px;
    border: 10px solid #e2e2e2 !important;
}

.imaging-options-list {
    list-style: none;
    padding-left: 10px;
    padding-top: 5px;
    line-height: 29px;
    margin: 0;
}

.imaging-options-list li [type=checkbox] {
    position: relative;
    top: 3px;
}

.imaging-options-list li label {
    margin-bottom: 0 !important;
}

.imaging-options-list li span {
    margin-left: 5px;
    font-size: 11px;
    font-weight: 400;
}

.imaging-options-modal hr {
    margin-top: 0;
    margin-left: 10px;
    width: 140px;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.3);
}

.imaging-select-all {
    padding-left: 10px;
}

.imaging-select-all [type=checkbox]  {
    position: relative;
    top: 3px;
}

.imaging-select-all span {
    margin-left: 5px;
    font-size: 11px;
    font-weight: 400;
}

.imaging-options-modal-buttons {
    width: 130px;
    text-align: center;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    padding-top: 4px;
}

.imaging-options-modal-buttons button {
    color: rgba(23, 50, 59, 0.9) !important;
    font-size: 12px;
    font-weight: 700;
    padding: 4px;
    width: 60px;
    background: -webkit-linear-gradient(bottom, #e7e6e6 1%, #ffffff 100%) !important;
    background: linear-gradient(to top, #e7e6e6 1%, #ffffff 100%) !important;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.imaging-options-modal-buttons button:hover {
    background: -webkit-linear-gradient(bottom, #e7e6e6 1%, #ffffff 70%) !important;
    background: linear-gradient(to top, #e7e6e6 1%, #ffffff 70%) !important;
    border: 1px solid #2caeff !important;
    cursor: default !important;
}
.patient-information-column .field {
    justify-content: space-between !important;
}

.patient-information-column .field label {
    width: 70px !important;
    flex: unset;
    padding-top: 5px;
    padding-left: 5px !important;
    line-height: 12px !important;
}

.patient-information-column .field .ui.input {
    margin: 0 !important;
    padding-right: 0 !important;
    width: 130px !important;
    flex: unset;
}

.patient-information-column input {
    width: 130px !important;
    border-radius: 0 !important;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    -ms-border-radius: 0 !important;
    -o-border-radius: 0 !important;
}

.patient-information-column img {
    margin-top: 2px;
}

.patient-information-column label {
    width: 42px;
}
.autoview-zoom {
    display: inline;
    position: absolute;
    top: 0;
    right: 12px;
}

.autoview-zoom-button img {
    padding-top: 1px;
    padding-right: 1px;
    width: 12px;
    height: 12px;
}

.autoview-zoom-button button:disabled img {
    filter: opacity(50%);
    -webkit-filter: opacity(50%);
}

.autoview-zoom-button button {
    width: 15px !important;
    height: 15px !important;
    padding: 0 !important;
    border-top: 1px solid lightgrey !important;
    border-bottom: 1px solid grey !important;
    border-left: 1px solid grey !important;
    border-right: 1px solid grey !important;
    background: rgba(255,255,255,0.8) !important;
}

.autoview-zoom-button button:hover {
    border: 1px solid #2caeff !important;
    cursor: pointer;
}

.autoview-zoom-button button:hover:disabled {
    border-top: 1px solid lightgrey !important;
    border-bottom: 1px solid grey !important;
    border-left: 1px solid grey !important;
    border-right: 1px solid grey !important;
    cursor: default;
}


.autoview-zoom-button button .icon {
    margin: 0 !important;
}

.scaled-autoview-modal {
    width: 875px !important;
    height: 565px !important;
    background: #e2e2e2 !important;
    padding: 0px 10px 10px 10px !important;
    
}

.scaled-autoview-modal .header {
    padding: 0 !important;
    border-bottom: none !important;
    font-size: 12px !important;
    color: #1b4049 !important;
    background: #e2e2e2 !important;
    margin-bottom: 5px !important;
}

.scaled-autoview-modal .content {
    padding: 0 !important;
    padding: 3px !important;
    height: 535px !important;
    background: white !important;
} 

.scaled-autoview-modal .close.icon {
    padding: 3px 0 0 0 !important;
    margin: 0 !important;
    font-size: 14px !important;
}

.scaled-autoview-modal .close.icon:hover {
    cursor: default !important;
}

.scaled-autoview-modal .slider-container {
    background: black !important;
}

.scaled-autoview.autoview-button-block {
    width: 280px !important;
    margin: 5px auto;
}

.scaled-autoview-modal .carousel .carousel__slider {
    width: 500px !important;
    height: 500px !important;
    margin: 0 auto !important;
    background: black !important;
}
  
.scaled-autoview-modal .carousel .carousel__slider-tray-wrapper {
    width: 500px !important;
    height: 500px !important;
}

.scaled-autoview-modal .carousel .carousel__slider img {
    height: 500px !important;
}


.zoomed-slide-white-overlay {
    position: absolute;
    top: 0;
    width: 500px;
    height: 500px;
    background:white;
    filter: opacity(0);
    -webkit-filter: opacity(0);
}
.scan-timing-table-row {
    display: flex;
    align-items: center;
    justify-content: space-around;
    text-align: center;
    color: #1b4049 !important;
}

.disabled-row {
    color: lightgray !important;
}

.scan-timing-table-coll {
    width: 30px;
    font-size: 9px;
    margin-bottom: 5px;
    line-height: 24px;
    text-align: left !important;
}

.scan-timing-table-coll-input-label {
    width: 50px;
    text-align: center;
    font-size: 9px;
}

.scan-timing-table-coll-input {
    width: 60px;
}

.scan-timing-table-coll-input input {
    padding-left: 5px !important;
    padding-right: 1px !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    font-size: 10px;
}

.scan-timing-table-coll-input .field {
    font-size: 12px;
    width: 60px;
    margin: 0 !important;
}
.scan-timing-table-coll-input .field .ui.input  {
    margin: 0 !important;
}

.scan-timing-table-coll-button button {
    padding: 0 !important;
    width: 16px !important;
    margin: 0 auto;
    height: 24px !important;
    border-top: 1px solid lightgrey !important;
    border-bottom: 1px solid grey !important;
    border-left: 1px solid grey !important;
    border-right: 1px solid grey !important;
    background: -webkit-linear-gradient(bottom, #b1bbc0 1%, #dfe5e7 100%) !important;
    background: linear-gradient(to top, #b1bbc0 1%, #dfe5e7 100%) !important;
    cursor: default !important;
}

.scan-timing-table-coll-button button:hover {
    background: -webkit-linear-gradient(bottom,#dfe5e7 1%, #ebf1f3 100%) !important;
    background: linear-gradient(to top,#dfe5e7 1%, #ebf1f3 100%) !important;
    border: 1px solid #2caeff !important;
}

.scan-timing-table-coll-button {
    width: 30px;
    align-self: flex-end;
    margin-bottom: 3px;
}

#tez-button {
    opacity: 0.5
}
#tez-button:hover {
    background: -webkit-linear-gradient(bottom, #b1bbc0 1%, #dfe5e7 100%) !important;
    background: linear-gradient(to top, #b1bbc0 1%, #dfe5e7 100%) !important;
    border-top: 1px solid lightgrey !important;
    border-bottom: 1px solid grey !important;
    border-left: 1px solid grey !important;
    border-right: 1px solid grey !important;
}
.navigation-block {
    text-align: center;
}

.navigation-block button {
    background: none !important;
    font-size: 12px !important;
    color: #17323b !important;
    padding: 5px 18px !important; 
    margin-top: 5px !important;
    margin-bottom: 5px !important;
}

.navigation-block button:hover {
    background: #b2e1ff !important;
}



.admin-password-modal {
    width: 380px !important;
    height: 150px !important;
    background: #e2e2e2 !important;
    padding: 10px !important;
}

.admin-password-modal .header {
    padding: 0 !important;
    font-size: 12px !important;
    color: #17323b !important;
    border: none !important;
    background-color: #e2e2e2 !important;
}

.admin-password-modal .close.icon {
    width: auto !important;
    height: auto !important;
    font-size: 14px !important;
    margin-right: 10px !important;
    padding-top: 0 !important;
    margin-top: 10px !important;
}

.admin-password-modal .close.icon:hover {
    cursor: default !important;
}

.admin-password-modal .content {
    text-align: center;
    font-size: 11px !important;
    color: #1b4049;
    margin-top: 10px !important;
    padding: 15px 30px !important;
    height: 105px !important;
}

.admin-password-modal .content .admin-password-input-group {
    width: 100% !important;
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
}

.admin-password-modal .content .admin-password-input-group input {
    height: 25px;
    width: 230px;
    font-size: 12px;
    border-top: 1px solid lightslategray;
    border-bottom: 1px solid lightgray;
    border-right: 1px solid lightgray;
    border-left: 1px solid lightgray;
}

.admin-password-modal .content .admin-password-input-group input:focus {
    box-shadow: 0px 0px 0px 1px #2caeff !important;
    outline: none !important;
}

.admin-password-modal .content button {
    float: right !important;
    color: rgba(23, 50, 59, 0.9) !important;
    font-size: 12px;
    font-weight: 700;
    padding: 4px;
    width: 60px;
    background: -webkit-linear-gradient(bottom, #cccccc 1%, #ffffff 100%) !important;
    background: linear-gradient(to top, #cccccc 1%, #ffffff 100%) !important;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.admin-password-modal .content button:hover {
    background: -webkit-linear-gradient(bottom, #cccccc 1%, #ffffff 70%) !important;
    background: linear-gradient(to top, #cccccc 1%, #ffffff 70%) !important;
    border: 1px solid #2caeff !important;
    cursor: default !important;
}

.admin-password-input-notification {
    float: left !important;
    color: red;
}
.empty-image, .empty-image > img {
  width: 250px !important;
  height: 250px !important;
  background-color: black;
  margin: 0 auto;
}

.carousel__slide-focus-ring {
  position: absolute;
  top: 0px !important;
  right: 0px !important;
  bottom: 0px !important;
  left: 0px !important;
  outline-width: 0px !important;
  outline-style: solid;
  outline-color: none !important;
}

.slide-white-overlay {
  position: absolute;
  top: 0;
  /* width: 250px;
  height: 250px; */
  width: 100%;
  height: 100%;
  background:white;
  filter: opacity(0);
  -webkit-filter: opacity(0);
}

@supports (-ms-ime-align:auto) { 
.slide-white-overlay {
  opacity: 0;
}
}

.autoview-button-block {
  margin-left: -3px;
  margin-top: 4px;
  margin-bottom: 3px;
}

.autoview-button-block button {
    height: 22px;
    width: 56px;
    font-size: 12px !important;
    margin: 0 5px !important;
    padding: 0 !important;
    border-radius: 5px !important;
    -webkit-border-radius: 5px !important;
    -moz-border-radius: 5px !important;
    -ms-border-radius: 5px !important;
    -o-border-radius: 5px !important;
    border-top: 1px solid lightgrey !important;
    border-bottom: 1px solid grey !important;
    border-left: 1px solid grey !important;
    border-right: 1px solid grey !important;
    background: -webkit-linear-gradient(bottom, #e7e6e6 1%, #ffffff 100%) !important;
    background: linear-gradient(to top, #e7e6e6 1%, #ffffff 100%) !important;
    color: rgba(23, 50, 59, 0.9) !important;
    font-weight: 700 !important;
    line-height: 22px !important;
}

.autoview-button-block button:hover {
  background: -webkit-linear-gradient(bottom, #e7e6e6 1%, #ffffff 70%) !important;
  background: linear-gradient(to top, #e7e6e6 1%, #ffffff 70%) !important;
  border: 1px solid #2caeff !important;
  cursor: default !important;
}

.autoview-button-block button:disabled {
  background: -webkit-linear-gradient(bottom, #cccccc 1%, rgba(255,255,255,0.8) 100%) !important;
  background: linear-gradient(to top, #cccccc 1%, rgba(255,255,255,0.8) 100%) !important;
  color: grey !important;
  border-top: 1px solid lightgrey !important;
  border-bottom: 1px solid grey !important;
  border-left: 1px solid grey !important;
  border-right: 1px solid grey !important;
}

.autoview-button-block .ui.dropdown, .empty .ui.drowdowns  {
  background: -webkit-linear-gradient(bottom, #e7e6e6 1%, #ffffff 100%) !important;
  background: linear-gradient(to top, #e7e6e6 1%, #ffffff 100%) !important;
  float: right !important;
  margin-right: 1px !important;
  height: 22px !important;
  min-height: 22px !important;
  width: 56px !important;
  color: rgba(23, 50, 59, 0.9) !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  padding: 0 !important;
  padding-left: 10px !important;
  line-height: 20px !important;
  border-top: 1px solid lightgrey !important;
  border-bottom: 1px solid grey !important;
  border-left: 1px solid grey !important;
  border-right: 1px solid grey !important;
}

.autoview-button-block .ui.dropdown:hover, .empty .ui.drowdowns:hover {
  background: -webkit-linear-gradient(bottom, #e7e6e6 1%, #ffffff 70%) !important;
  background: linear-gradient(to top, #e7e6e6 1%, #ffffff 70%) !important;
  border: 1px solid #2caeff !important;
  cursor: default;
}

.autoview-button-block .ui.disabled.dropdown , .empty .ui.disabled.dropdown {
  background: -webkit-linear-gradient(bottom, #cccccc 1%, #ffffff 100%) !important;
  background: linear-gradient(to top, #cccccc 1%, #ffffff 100%) !important;
  opacity: 0.8;
}

.autoview-button-block .ui.selection.dropdown .item, .empty .ui.selection.dropdown .item {
  height: 22px !important;
  border: none !important;
  padding: 0 !important;
  padding-left: 10px !important;
  line-height: 22px !important;
  font-size: 12px !important;
}

.autoview-button-block .ui.selection.dropdown .active.selected.item, .empty .ui.selection.dropdown .active.selected.item {
  height: 22px !important;
  border: none !important;
  padding: 0 !important;
  padding-left: 10px !important;
  background: #7fceff !important;
  font-size: 12px !important;
}

.autoview-button-block .ui.dropdown .item:hover, .empty .ui.drowdowns .item:hover {
  background: #b2e1ff !important;
  cursor: default !important;
}


.autoview-button-block .ui.dropdown>.dropdown.icon, .empty .ui.drowdowns>.dropdown.icon {
  padding: 0 !important;
  padding-right: 3px !important;
  padding-left: 5px !important;
  font-size: 14px !important;
  margin-top: -10px !important;
  height: 18px !important;
  border-left: 1px solid rgba(23, 50, 59, 0.3) !important;
}

.autoview-button-block .ui.dropdown:hover>.dropdown.icon, .empty .ui.drowdowns:hover>.dropdown.icon {
  border-left: 1px solid #2caeff !important;
}

.autoview-button-block .ui.dropdown>.dropdown.icon:hover, .empty .ui.drowdowns>.dropdown.icon:hover {
  cursor: default;
}

.empty button {
  background: -webkit-linear-gradient(bottom, #cccccc 1%, rgba(255,255,255,0.8) 100%) !important;
  background: linear-gradient(to top, #cccccc 1%, rgba(255,255,255,0.8) 100%) !important;
  color: grey !important;
}

.empty button:hover {
  border-top: 1px solid lightgrey !important;
  border-bottom: 1px solid grey !important;
  border-left: 1px solid grey !important;
  border-right: 1px solid grey !important;
  background: -webkit-linear-gradient(bottom, #cccccc 1%, rgba(255,255,255,0.8) 100%) !important;
  background: linear-gradient(to top, #cccccc 1%, rgba(255,255,255,0.8) 100%) !important;
}

.autoview-column .autoview-container .carousel .carousel__slider {
  width: 250px !important;
  margin: 0 auto !important;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.autoview-column .autoview-container .carousel .carousel__slider-tray-wrapper {
  width: 250px !important;
  height: 250px !important;
  background: black !important;
}

.recon-modal {
    background: #8197a2 !important;
    height: 550px !important;
    width: 550px !important;
    padding: 0 10px 10px 10px !important;
}

.recon-modal .header {
    padding: 5px 0 !important;
    border-bottom: none !important;
    background: #8197a2 !important;
    color: #04161a !important;
    font-size: 12px !important;
}

.recon-modal .close.icon {
    margin: 0 !important;
    padding: 5px 0 0 0 !important;
    font-size: 14px !important;
}

.recon-modal .close.icon:hover {
    cursor: default !important;
}

.recon-modal .content {
    /* overflow: scroll !important; */
    padding: 0 !important;
    background: #95abb6 !important;
    max-height: 515px !important;
    max-width: 530px !important;
    padding-top: 5px !important;
}

.recon-modal-content-header {
    text-align: center;
    padding-top: 0px !important;
    /* padding-left: 10px !important; */
    margin: 0 auto 10px auto !important;
    width: 540px !important;
    color: #f6f6e0;
    text-transform: uppercase;
    font-size: 15px !important;
    font-family: Verdana, Geneva, Tahoma, sans-serif !important;
    font-weight: bold !important;
}

.recon-image-container {
    width: 400px;
    margin: 0 auto;
}

.recon-image-container .carousel__slider {
    background: black;
}

#recon-prior-button, #recon-next-button {
    height: 35px !important;
    width: 80px !important;
    font-size: 18px !important;
}

#recon-dropdown {
    height: 35px !important;
    width: 80px !important;
    font-size: 16px !important;
    line-height: 30px !important;
    position: relative;
    z-index: 20;
}

#recon-dropdown .item {
    height: 35px !important;
    line-height: 30px !important;
    font-size: 16px !important;
    position: relative;
    z-index: 20;
    /* z-index: 10; */
}

#recon-dropdown>.dropdown.icon {
    height: 30px !important;
    padding-left: 12px !important;
    padding-right: 10px !important;
    padding-top: 3px !important;
    font-size: 18px !important;
    margin-top: -13px !important;
}

.recon-navigation-bottom {
    padding-top: 10px;
    padding-bottom: 5px;
    /* padding-left: 35px; */
    display: flex;
    width: 100%;
    justify-content: center;
}

.recon-navigation-bottom > button {
    height: 22px;
    width: 60px;
    font-size: 12px !important;
    margin: 0 5px !important;
    padding: 0 !important;
    border-radius: 5px !important;
    -webkit-border-radius: 5px !important;
    -moz-border-radius: 5px !important;
    -ms-border-radius: 5px !important;
    -o-border-radius: 5px !important;
    border-top: 1px solid lightgrey !important;
    border-bottom: 1px solid grey !important;
    border-left: 1px solid grey !important;
    border-right: 1px solid grey !important;
    background: -webkit-linear-gradient(bottom, #b1bbc0 1%, #dfe5e7 100%) !important;
    background: linear-gradient(to top, #b1bbc0 1%, #dfe5e7 100%) !important;
    color: rgba(23, 50, 59, 0.9) !important;
    font-weight: 700 !important;
    line-height: 20px !important;
}

.recon-navigation-bottom > button:hover {
    background: -webkit-linear-gradient(bottom,#dfe5e7 1%, #ebf1f3 100%) !important;
    background: linear-gradient(to top,#dfe5e7 1%, #ebf1f3 100%) !important;  
    border: 1px solid #2caeff !important;
    cursor: default !important;
}

.recon-navigation-bottom > button:disabled {
    background: -webkit-linear-gradient(bottom, #b1bbc0 1%, #dfe5e7 100%) !important;
    background: linear-gradient(to top, #b1bbc0 1%, #dfe5e7 100%) !important;
    color: grey !important;
    border-top: 1px solid lightgrey !important;
    border-bottom: 1px solid grey !important;
    border-left: 1px solid grey !important;
    border-right: 1px solid grey !important;
}

.recon-navigation-bottom .draw-button {
    width: 90px;
}

.recon-canvas-container {
    position: absolute;
    z-index: 2;
}


.error-modal-message {
    width: 315px !important;
    height: 100px !important;
    padding: 20px 10px 10px 10px !important;
    background: rgba(124, 143, 153, 0.8) !important;
}

.error-modal-message .content {
    width: 100% !important;
    height: 100% !important;
    text-align: center;
    border: 1px solid #dddddd !important;
    padding: 10px 0 0 0 !important;
    border-radius: 0 !important;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    -ms-border-radius: 0 !important;
    -o-border-radius: 0 !important;
    background: #dddddd !important;
}

.error-modal-message p {
    font-size: 11px !important;
}

.error-modal-message button {
    margin-top: 3px;
    height: 22px !important;
    width: 65px !important;
    font-size: 11px;
    font-weight: bold;
    background: -webkit-linear-gradient(bottom, #cecece 1%, #ffffff 100%) !important;
    background: linear-gradient(to top, #cecece 1%, #ffffff 100%) !important;
    border: 2px solid grey;
    border-radius: .28571429rem;
    -webkit-border-radius: .28571429rem;
    -moz-border-radius: .28571429rem;
    -ms-border-radius: .28571429rem;
    -o-border-radius: .28571429rem;
}

.error-modal-message button:hover {
    background: white !important;
    border: 1px solid grey
}
#graphicRX {
    width: 100%;
    height: 100%;
}

.plan-slices {
    display: contents;
}

.padding-ctrl {
    padding: 0 !important;
    width: 12% !important;
}

.select-padding-ctrl {
    padding: 0 !important;
    width: 32% !important;
    font-size: 0.8em !important;
}


 .plan-slices-dashboard {
    z-index: 2;
    height:  250px !important;
    width:  250px !important;
    position: absolute;
    border: 1px solid grey;
} 

.empty-image {
    overflow: hidden;
}

.plan-slices-slider-container {
    overflow: hidden;
    background: black;
}
.add-params {
    width: 237px !important;
    height: 150px !important;
    color: #1b4049 !important;
    margin: 0 auto !important;
}

.add-params .ui.button, .add-params .ui.button:disabled{
    width: 53px;
    height: 53px;
    padding: 0;
    margin: 0 !important;
    border-radius: 5px !important;
    -webkit-border-radius: 5px !important;
    -moz-border-radius: 5px !important;
    -ms-border-radius: 5px !important;
    -o-border-radius: 5px !important;
    border-top: 1px solid lightgrey;
    border-bottom: 1px solid grey;
    border-left: 1px solid grey;
    border-right: 1px solid grey;
    background: -webkit-linear-gradient(bottom, #b1bbc0 1%, #dfe5e7 100%) !important;
    background: linear-gradient(to top, #b1bbc0 1%, #dfe5e7 100%) !important;
    cursor: default !important;

}

.add-params .button-caption {
    text-align: center;
    padding: 7px 0;
    font-size: 9px
}

.add-params .ui.grid>.row>.column {
    padding: 0!important;
    width: 53px !important;
    margin-left: 3px;
    margin-right: 3px;
}

.add-params .button img {
    margin-top: -1px;
    width: 52px;
    max-height: 52px;
}

.image-container {
    /* position: relative; */
    width: auto;
    height: auto !important;
}

.image-container img {
    width: 100%;
    position: relative;
}

.button-center {
    margin: 10px auto;
}

.of-slices-input {
    width: 60px;
    margin-left: 10px;
}

.buttom-buttons {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.buttom-buttons .button {
    margin: 5px 15px;
}

.buttons-center .button {
    margin: 5px 15px;
}


.plan-slices-modal, .plan-slices-modal-moved {
    width: 700px !important;
    height: 535px !important;
    background: #98a9b1 !important;
    padding: 5px 10px 10px 10px !important;
    position: relative !important;
    /* top: -50px; */
}

.plan-slices-modal .header, .plan-slices-modal-moved .header {
    padding: 0 !important;
    margin-bottom: 5px !important;
    background: #98a9b1 !important;
    font-size: 12px !important;
    color: #0c343d !important;
    border-bottom: none !important;
}

.plan-slices-modal .close.icon, .plan-slices-modal-moved .close.icon {
    padding: 5px 20px 0 0 !important;
    margin: 0 !important;
    font-size: 14px !important;
    height: 14px !important;
    width: 14px !important;
}

.plan-slices-modal .close.icon:hover, .plan-slices-modal-moved .close.icon:hover {
    cursor: default !important;
}

.plan-slices-modal .content, .plan-slices-modal-moved .content {
    padding: 0 !important;
}

.plan-slices-modal-content-block {
    display: flex;
}

.plan-slices-modal-content {
    background: #98a9b1
}

.plan-slices-modal-content-block .button-block-item {
    width: 365px;
    max-width: 365px;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    color: #0c343d;
    padding-top: 2px;
}

.viewports-block {
    width: 1020px;
    display: flex;
    flex-wrap: wrap;
}

.start-end-table-cell {
    border: 1px solid #576469;
    text-align: center;
    padding: 0 10px;
    font-size: 10px;
    font-weight: bold;
}

.graphic-rx-bottom {
    padding: 5px 0;
    /* margin-bottom: 5px; */
    border-top: none !important;
    border-bottom: none !important;
    border-right: none !important;
    width: 250px;
    height: 55px;
    margin-left: 0px;
    display: flex;
    justify-content: space-between
}

.graphic-rx-bottom .field {
    width: 50px;
    margin: 0;
    height: 20px;
}

.graphic-rx-bottom .field .ui.input>input{
    width: 35px;
    height: 20px;
    font-size: 12px;
    padding: 0;
    text-align: center
} 

.vieport-part-buttons > div {
    border: 1px solid gray;
    border-right: none;
}

.vieport-part-buttons-half {
    width: 125px;
    height: 205px;
    text-align: center;
    /* display: flex; */
}

.vieport-part-buttons-half.input-side {
    padding-right: 5px;
}

.vieport-part-buttons-half.input-side .td-title {
    font-size: 10px;
    text-align: right;
}

.vieport-part-buttons-half.input-side .td-input input {
    width: 50px;
    height: 20px;
    margin: 0 auto;
    padding: 0;
    padding-left: 5px;
    font-size: 10px;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
}

.vieport-part-buttons-half.input-side .td-input .field {
    margin: 0;
    height: 21px;
}

.input-side label {
    margin-bottom: 0;
    font-size: 10px;
    text-align: right;
    margin-right: 5px;
}

.td-input input[type='number']::-webkit-inner-spin-button, 
.td-input input[type='number']::-webkit-outer-spin-button {  
   -webkit-appearance: textfield;
   opacity: 1;
   /* height: 20px; */
   -moz-appearance:textfield;
}

.vieport-part-buttons {
    width: 250px;
    height: 250px;
    display: flex;
    flex-wrap: wrap;
}

.button-block-item button {
    font-size: 12px;
    font-weight: bold;
    color: #0c343d;

    border-top: 1px solid lightgrey !important;
    border-bottom: 1px solid grey !important;
    border-left: 1px solid grey !important;
    border-right: 1px solid grey !important;
    background: -webkit-linear-gradient(bottom, #e7e6e6 1%, #ffffff 100%) !important;
    background: linear-gradient(to top, #e7e6e6 1%, #ffffff 100%) !important;
    border-radius: .28571429rem;
    -webkit-border-radius: .28571429rem;
    -moz-border-radius: .28571429rem;
    -ms-border-radius: .28571429rem;
    -o-border-radius: .28571429rem;
}

.button-block-item button:hover {
    background: -webkit-linear-gradient(bottom, #e7e6e6 1%, #ffffff 70%) !important;
    background: linear-gradient(to top, #e7e6e6 1%, #ffffff 70%) !important;
    border: 1px solid #2caeff !important;
    cursor: default !important;
}

.button-block-item button:disabled {
    background: -webkit-linear-gradient(bottom, #e7e6e6 1%, #ffffff 100%) !important;
    background: linear-gradient(to top, #e7e6e6 1%, #ffffff 100%) !important;
    color: rgba(12, 52, 61, 0.3) !important;
    border-top: 1px solid rgba(211, 211, 211, 0.5) !important;
    border-bottom: 1px solid rgba(128, 128, 128, 0.7) !important;
    border-left: 1px solid rgba(128, 128, 128, 0.5) !important;
    border-right: 1px solid rgba(128, 128, 128, 0.5) !important;
}

.button-block-item-main-buttons {
    width: 170px;
    height: 22px;
    /* margin-top: 3px; */
    /* margin-bottom: 2px; */
}

.button-box-block {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.buttons-box {
    width: 85px;
    height: 45px;
    border-radius: 0 !important;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    -ms-border-radius: 0 !important;
    -o-border-radius: 0 !important;
}

.button-block-item input[type="text"] {
    margin-left: 10px;
    width: 30px;
    height: 22px;
    padding: 0 0 0 2px;
    font-size: 10px;
    font-weight: bold;
    color: #0c343d;
    line-height: 22px;

    border-top: 1px solid black;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
}

.button-block-item .rotation-buttons {
    width: 40px;
    height: 22px;
    margin: 2px 3px;
}

.button-block-item .text-remark {
    font-weight: normal;
    font-size: 10px;
    width: 160px;
}

.sat-rx-buttons {
    width: 55px;
    height: 30px;
    margin: 15px 3px 3px 3px;
    font-size: 12px;
    font-weight: bold;
    color: #0c343d;

    border-top: 1px solid lightgrey !important;
    border-bottom: 1px solid grey !important;
    border-left: 1px solid grey !important;
    border-right: 1px solid grey !important;
    background: -webkit-linear-gradient(bottom, #e7e6e6 1%, #ffffff 100%) !important;
    background: linear-gradient(to top, #e7e6e6 1%, #ffffff 100%) !important;
    border-radius: .28571429rem;
    -webkit-border-radius: .28571429rem;
    -moz-border-radius: .28571429rem;
    -ms-border-radius: .28571429rem;
    -o-border-radius: .28571429rem;
}

.sat-rx-buttons:hover {
    background: -webkit-linear-gradient(bottom, #e7e6e6 1%, #ffffff 70%) !important;
    background: linear-gradient(to top, #e7e6e6 1%, #ffffff 70%) !important;
    border: 1px solid #2caeff !important;
    cursor: default !important;
}

.sat-rx-buttons:disabled {
    background: -webkit-linear-gradient(bottom, #e7e6e6 1%, #ffffff 100%) !important;
    background: linear-gradient(to top, #e7e6e6 1%, #ffffff 100%) !important;
    color: rgba(12, 52, 61, 0.3) !important;
    border-top: 1px solid rgba(211, 211, 211, 0.5) !important;
    border-bottom: 1px solid rgba(128, 128, 128, 0.7) !important;
    border-left: 1px solid rgba(128, 128, 128, 0.5) !important;
    border-right: 1px solid rgba(128, 128, 128, 0.5) !important;
}

.plan-slices-slider-container, .plan-slices-slider-container img {
    width: 250px;
    height: 250px;
}

.fat-water-list {
    list-style: none;
    text-align: left;
    margin-left: 0;
    padding-left: 5px;
    color: #0c343d;
    font-weight: bold;
}

.fat-water-list input {
    margin-right: 8px;
    position: relative;
    top: 2px;
}

.plan-slices-image-button-block {
    margin-top: 3px;
    font-size: 12px;
    font-weight: bold;
    color: #0c343d;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.plan-slices-image-button-block button {
    font-size: 12px;
    font-weight: bold;
    color: #0c343d;

    border-top: 1px solid lightgrey !important;
    border-bottom: 1px solid grey !important;
    border-left: 1px solid grey !important;
    border-right: 1px solid grey !important;
    background: -webkit-linear-gradient(bottom, #e7e6e6 1%, #ffffff 100%) !important;
    background: linear-gradient(to top, #e7e6e6 1%, #ffffff 100%) !important;
    border-radius: .28571429rem;
    -webkit-border-radius: .28571429rem;
    -moz-border-radius: .28571429rem;
    -ms-border-radius: .28571429rem;
    -o-border-radius: .28571429rem;
}

.plan-slices-image-button-block button:hover {
    background: -webkit-linear-gradient(bottom, #e7e6e6 1%, #ffffff 70%) !important;
    background: linear-gradient(to top, #e7e6e6 1%, #ffffff 70%) !important;
    border: 1px solid #2caeff !important;
    cursor: default !important;
}

.plan-slices-image-button-block button:disabled {
    background: -webkit-linear-gradient(bottom, #e7e6e6 1%, #ffffff 100%) !important;
    background: linear-gradient(to top, #e7e6e6 1%, #ffffff 100%) !important;
    color: rgba(12, 52, 61, 0.3) !important;
    border-top: 1px solid rgba(211, 211, 211, 0.5) !important;
    border-bottom: 1px solid rgba(128, 128, 128, 0.7) !important;
    border-left: 1px solid rgba(128, 128, 128, 0.5) !important;
    border-right: 1px solid rgba(128, 128, 128, 0.5) !important;
}

.accept-button {
    /* width: 115px !important; */
    height: 22px;
}

.change-image-block {
    margin-bottom: 115px;
}

.button-change-image {
    width: 30px;
    height: 30px;
    margin: 5px;
}

.copy-rx-button {
    width: 114px;
    display: inline-block;
}

.ui.button.dropdown-block-rx, .copy-rx-button {
    /* margin-top: 5px; */
    width: 170px;
    text-align: center;
    margin-bottom: 5px;
    height: 22px;
    line-height: 0px; 
    font-size: 13px;
    font-weight: bold;
    color: #0c343d;

    border-top: 1px solid lightgrey !important;
    border-bottom: 1px solid grey !important;
    border-left: 1px solid grey !important;
    border-right: 1px solid grey !important;
    background: -webkit-linear-gradient(bottom, #e7e6e6 1%, #ffffff 100%) !important;
    background: linear-gradient(to top, #e7e6e6 1%, #ffffff 100%) !important;
    border-radius: .28571429rem;
    -webkit-border-radius: .28571429rem;
    -moz-border-radius: .28571429rem;
    -ms-border-radius: .28571429rem;
    -o-border-radius: .28571429rem;
}

.ui.button.dropdown-block-rx:hover {
    background: -webkit-linear-gradient(bottom, #e7e6e6 1%, #ffffff 70%) !important;
    background: linear-gradient(to top, #e7e6e6 1%, #ffffff 70%) !important;
    border: 1px solid #2caeff !important;
    cursor: default !important;
}

.ui.button.dropdown-block-rx:disabled {
    background: -webkit-linear-gradient(bottom, #e7e6e6 1%, #ffffff 100%) !important;
    background: linear-gradient(to top, #e7e6e6 1%, #ffffff 100%) !important;
    color: rgba(12, 52, 61, 0.3) !important;
    border-top: 1px solid rgba(211, 211, 211, 0.5) !important;
    border-bottom: 1px solid rgba(128, 128, 128, 0.7) !important;
    border-left: 1px solid rgba(128, 128, 128, 0.5) !important;
    border-right: 1px solid rgba(128, 128, 128, 0.5) !important;
}

.ui.button.dropdown-block-rx .item {
    height: 22px !important;
    line-height: 22px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.dropdown-block-rx .item:hover {
    background: #b2e1ff !important
}
.dropdown-block-rx .active.selected.item {
    background: #7fceff !important;
    height: 22px !important;
    line-height: 22px !important;
}

.dropdown-block-rx > .visible.menu {
    max-height: 200px;
    overflow: hidden;
    overflow-y: auto;
}

.plan-slices-image-button-block .display-buttons {
    width: 70px;
    height: 40px;
}

.plan-slices-image-button-block .zoom-input-block {
    text-align: center;
}

.zoom-input-block input[type='range'] {
    width: 145px;
    -webkit-appearance: none;
    background: transparent;
}

.zoom-input-block input[type=range]:focus {
    outline: none;
}

.zoom-input-block input[type='range']::-webkit-slider-runnable-track {
    width: 145px;
    height: 5px;
    background: #ddd;
    border: none;
    border-radius: 3px;
}

.zoom-input-block input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: grey;
    margin-top: -5px;
}

.zoom-input-block input[type='range']::-moz-range-track { 
    width: 145px;
    height: 5px;
    background: #ddd;
    border: none;
    border-radius: 3px;
}

.zoom-input-block input[type=range]::-moz-range-thumb {
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: grey;
}

.zoom-input-block input[type=range]:-moz-focusring{
    outline: 1px solid white;
    outline-offset: -1px;
}

.zoom-input-block input[type=range]:focus::-moz-range-track {
    outline: none;
}

.zoom-input-block input[type='range']::-ms-track { 
    width: 145px;
    height: 5px;
    background: transparent;
    border-color: transparent;
    border-width: 6px 0;
    color: transparent;
}

.zoom-input-block input[type=range]::-ms-fill-lower {
    background: #ccc;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.zoom-input-block input[type=range]::-ms-fill-upper {
    background: #ddd;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.zoom-input-block input[type=range]::-ms-thumb {
    border: none;
    height: 16px;
    width: 16px;
    margin-bottom: -5px;
    border-radius: 50%;
    background: grey;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.zoom-input-block input[type=range]:focus::-ms-fill-upper {
    background: #ccc;
}

.zoom-input-block p {
    text-align: left;
    padding-left: 18px;
    margin-top: 5px;
}

@supports (-ms-ime-align: auto) {
.zoom-input-block p {
    text-align: left;
    padding-left: 18px;
    margin-top: -8px;
}
}

.plan-slices-image-button-block .prior-next-block {
    width: 90px;
    text-align: center;
}

.plan-slices-image-button-block .prior-next-block button {
    width: 40px;
    height: 22px;
    margin: 0 2px 10px 2px;
}

.plan-slices-image-button-block .prior-next-block span {
    color: rgba(12, 52, 61, 0.3);
}

.plan-slices-image-button-block .ui.dropdown {
    background: -webkit-linear-gradient(bottom, #e7e6e6 1%, #ffffff 100%) !important;
    background: linear-gradient(to top, #e7e6e6 1%, #ffffff 100%) !important;
    float: right !important;
    margin-right: 1px !important;
    height: 22px !important;
    min-height: 22px !important;
    width: 56px !important;
    color: rgba(23, 50, 59, 0.9) !important;
    font-size: 12px !important;
    font-weight: 700 !important;
    padding: 0 !important;
    padding-left: 10px !important;
    line-height: 20px !important;
    border-top: 1px solid lightgrey !important;
    border-bottom: 1px solid grey !important;
    border-left: 1px solid grey !important;
    border-right: 1px solid grey !important;
}

.plan-slices-image-button-block .ui.dropdown:hover {
    background: -webkit-linear-gradient(bottom, #e7e6e6 1%, #ffffff 70%) !important;
    background: linear-gradient(to top, #e7e6e6 1%, #ffffff 70%) !important;
    border: 1px solid #2caeff !important;
    cursor: default;
}

.plan-slices-image-button-block .ui.selection.dropdown .item {
    height: 22px !important;
    border: none !important;
    padding: 0 !important;
    padding-left: 10px !important;
    line-height: 22px !important;
    font-size: 12px !important;
}

.plan-slices-image-button-block .ui.selection.dropdown .active.selected.item {
    height: 22px !important;
    border: none !important;
    padding: 0 !important;
    padding-left: 10px !important;
    background: #7fceff !important;
    font-size: 12px !important;
}

.plan-slices-image-button-block .ui.dropdown .item:hover {
    background: #b2e1ff !important;
    cursor: default !important;
}

.plan-slices-image-button-block .ui.dropdown>.dropdown.icon {
    padding: 0 !important;
    padding-right: 3px !important;
    padding-left: 5px !important;
    font-size: 14px !important;
    margin-top: -10px !important;
    height: 18px !important;
    border-left: 1px solid rgba(23, 50, 59, 0.3) !important;
}

.plan-slices-image-button-block .ui.dropdown:hover>.dropdown.icon {
    border-left: 1px solid #2caeff !important;
}

.plan-slices-image-button-block .ui.dropdown>.dropdown.icon:hover {
    cursor: default;
}

.autoview-graphic-rx {
    width: 300px;
    height: 300px;
    position: absolute;
    /* top: 0; */
    /* left: -302px; */
    z-index: 100;
}

.autoview-graphic-rx .close.icon {
    padding-top: 0px !important;
    padding-right: 0px !important; 
    margin: 0;
    font-size: 14px;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
}

.graphic-rx-autoview-header {
    border-bottom: none !important;
    margin-bottom: 0 !important;
    background: #98a9b1 !important;
    padding: 3px 10px !important;
    color: rgba(23, 50, 59, 0.9) !important;
    font-size: 13px !important;
    font-weight: bold;
    display: flex;
    justify-content: space-between
}

.autoview-graphic-rx > .content {
    padding: 0px 10px 10px 10px !important;
    background: #98a9b1 !important;
}

.slices-input .ui.input {
    width: 25px !important;
    /* height: 20px !important; */
    padding: 0 !important;
}

.slices-input .ui.input input {
    height: 20px !important;
    line-height: 20px !important;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
}

.rx-slide-white-overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background:white;
    opacity: 0;
    z-index: 0;
}

.plan-slices-modal-moved {
    position: relative !important;
	left: -250px;
}

@media (min-height:1023px) and (max-height: 1025px) {
.autoview-graphic-rx {
    width: 250px !important;
    height: 250px !important;
}
.plan-slices-modal-moved {
	left: -155px;
}
}

@media (max-width: 1025px) {
.autoview-graphic-rx {
    width: 250px !important;
    height: 250px !important;
}
.plan-slices-modal-moved {
	left: -155px;
}
}
.rx-manager {
    height: 295px !important;
    /* width: 204px; */
}

.rx-manager .ui.buttons {
    justify-content: space-around;
    width: 210px;
}

.table-body {
    max-height: 215px !important;
    /* overflow-y: auto; */
    margin: 32px auto 0 auto;
    max-width: 204px !important;
}

.table-body-empty {
    overflow-y: hidden !important;
}

.header-number {
    height: 25px; 
    width: 22px;
    display: inline-block;
    text-align: center;
    position: absolute;
    top: 53px;
    left: 0px;
    background: -webkit-linear-gradient(bottom, #cecece 1%, #ffffff 50%) !important;
    background: linear-gradient(to top, #cecece 1%, #ffffff 50%) !important;
    font-weight: bold !important;
    font-size: 12px;
    color: rgba(23, 50, 59, 0.9) !important;
    line-height: 23px;
    border-right: 1px solid lightgrey;
}
.header-title {
    height: 25px; 
    width: 182px;
    display: inline-block;
    text-align: left;
    position: absolute;
    top: 53px;
    left: 22px;
    background: -webkit-linear-gradient(bottom, #cecece 1%, #ffffff 50%) !important;
    background: linear-gradient(to top, #cecece 1%, #ffffff 50%) !important;
    font-weight: bold !important;
    font-size: 12px;
    color: rgba(23, 50, 59, 0.9) !important;
    line-height: 23px;
    padding-left: 5px;
}

.rx-manager-series-table {
    margin-top: 5px !important;
    border-radius: 0 !important;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    -ms-border-radius: 0 !important;
    -o-border-radius: 0 !important;
}
 
.rx-manager-series-table .table-number {
    width: 20px !important;
    text-align: center !important;
    padding-left: 0 !important;
    color: #3c5c63 !important;
}

.rx-manager-series-table tbody tr td {
    height: 25px !important;
    padding: 0 0 0 5px !important;
    text-align: left !important;
    color: #3c5c63 !important;
}

.rx-manager-series-table .table-serie-body tr:hover {
    background: #b2e1ff !important;
    cursor: default;
}

.rx-manager-series-table tbody tr:nth-child(odd) {
    background: #f7f7f7;
}

.rx-manager-series-table.ui.table tr.active {
    background: #7fceff !important;
}

.rx-manager .buttons {
    margin-top: 3px;
    width: 100% !important;
    display: flex !important;
    justify-content: center;
}
.rx-manager .buttons button {
    height: 24px;
    width: 70px;
    font-size: 12px;
    margin: 0 5px;
    padding: 0;
    border-radius: 5px !important;
    -webkit-border-radius: 5px !important;
    -moz-border-radius: 5px !important;
    -ms-border-radius: 5px !important;
    -o-border-radius: 5px !important;
    border-top: 1px solid lightgrey;
    border-bottom: 1px solid grey;
    border-left: 1px solid grey;
    border-right: 1px solid grey;
    background: -webkit-linear-gradient(bottom, #b1bbc0 1%, #dfe5e7 100%);
    background: linear-gradient(to top, #b1bbc0 1%, #dfe5e7 100%);
    color: rgba(23, 50, 59, 0.9);
    font-weight: 700;
    font-size: 13px;
}

.rx-manager .end-exam-button {
    width: 86px !important;
}

.rx-manager .buttons button:hover {
    background: -webkit-linear-gradient(bottom,#dfe5e7 1%, #ebf1f3 100%);
    background: linear-gradient(to top,#dfe5e7 1%, #ebf1f3 100%);
    cursor: default;
    border: 1px solid #2caeff;
}

.rx-manager .active {
    background-color: skyblue;
}
.scan-params-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 46px;
  color: #1b4049 !important;
}

.rx-scan-time {
  outline: none;
}

.rx-scan-time-bordered {
  outline: 2px solid #cd3142;
}


.scan-params-row-item > div:first-child {
  margin-top: 5px;
  margin-bottom: 15px;
}

.modal-scan-buttons {
  text-align: center !important;
  padding: 5px 0 0 0 !important;
}

.modal-scan-header.header {
  font-size: 12px !important;
  font-weight: 700 !important;
  color: black !important;
  text-transform: none;
  padding: inherit !important;
  background: #e2e2e2 !important;
  border: none !important;
  border-radius: 0 !important;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  -ms-border-radius: 0 !important;
  -o-border-radius: 0 !important;
  padding-bottom: 7px !important;
  padding-top: 3px !important;
  padding-left: 2px !important;
  color: #17323b !important;
}

.modal-scan-buttons > button {
  height: 23px;
  width: 102px;
  font-size: 11px;
  margin-right: 5px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  background: -webkit-linear-gradient(bottom, #e7e6e6 1%, #ffffff 80%) !important;
  background: linear-gradient(to top, #e7e6e6 1%, #ffffff 80%) !important;
  font-weight: bold !important;
  color: #17323b !important;
}

.modal-scan-buttons > button:hover {
  background: -webkit-linear-gradient(bottom, #e7e6e6 1%, #ffffff 100%) !important;
  background: linear-gradient(to top, #e7e6e6 1%, #ffffff 100%) !important;
  cursor: pointer !important;
}

.modal-scan-pause {
  border-top: 0px solid #e2e2e2 !important;
  border-bottom: 10px solid #e2e2e2 !important;
  border-left: 10px solid #e2e2e2 !important;
  border-right: 10px solid #e2e2e2 !important;
  width: 300px !important;
  height: 70px !important;
}

.zero-scan-time {
  color: #c72a2b;
}

.incorrect-time-popup {
  background: #c72a2b !important;
  border: 1px solid #c72a2b !important;
  color: white !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  top: 584px !important;
  right: 594px !important;
}

.incorrect-time-popup::before {
  background: #c72a2b !important;
  border: 1px solid #c72a2b !important;
}
.acquisition-timing {
    height: 155px !important;
    width: 255px;
    color: #1b4049 !important;
}


.acquisition-timing-table-row {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.acquisition-timing-table {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    height: 130px !important;
    flex-wrap: wrap;
    width: 255px;
    padding-left: 15px; 
}
.acquisition-timing-table-coll {
    font-size: 12px;
    align-self: flex-end;
}

.acquisition-timing-table-coll-input-label {
    width: 30px !important;
    text-align: right;
}

.acquisition-timing-table-coll .ui.input {
    width: 50px !important;
}
.acquisition-timing-table-coll-input .field {
    font-size: 12px;
    width: 60px;
    margin: 0 !important;
}

.acquisition-timing-table-coll-input input {
    padding-left: 5px !important;
    padding-right: 1px !important;
    font-size: 10px;
}

.acquisition-timing-table-coll-button button {
    padding: 0 !important;
    width: 16px !important;
    display: block;
    margin: 0 auto;
    height: 24px;
    border-top: 1px solid lightgrey !important;
    border-bottom: 1px solid grey !important;
    border-left: 1px solid grey !important;
    border-right: 1px solid grey !important;
    background: -webkit-linear-gradient(bottom, #b1bbc0 1%, #dfe5e7 100%) !important;
    background: linear-gradient(to top, #b1bbc0 1%, #dfe5e7 100%) !important;
    cursor: default !important;
}

.acquisition-timing-table-coll-button button:hover {
    background: -webkit-linear-gradient(bottom,#dfe5e7 1%, #ebf1f3 100%) !important;
    background: linear-gradient(to top,#dfe5e7 1%, #ebf1f3 100%) !important;
    cursor: default !important;
    border: 1px solid #2caeff !important;
}

.acquisition-timing-table-coll-button {
    width: 20px;
}

.scaning-range-table-row {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0;
    padding: 0;
    color: #1b4049 !important;
}

.scaning-range-table {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    height: 100px;
    width: 450px;
    flex-wrap: wrap;
}
.scaning-range-table-coll {
    width: 50px;
    font-size: 12px;
    align-self: flex-end;
    margin-bottom: 5px;
}

.scaning-range-table-coll-input-label {
    width: 100px !important;
    text-align: right;
}

.scaning-range-table-coll-input .field {
    font-size: 12px;
    width: 60px;
    margin: 0 !important;
}

.scaning-range-table-coll-input input {
    padding-left: 5px !important;
    padding-right: 1px !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    font-size: 10px;
}

.scaning-range-table-coll-button button {
    padding: 0 !important;
    width: 16px;
    display: block;
    margin: 0 auto;
    height: 24px;
    border-top: 1px solid lightgrey !important;
    border-bottom: 1px solid grey !important;
    border-left: 1px solid grey !important;
    border-right: 1px solid grey !important;
    background: -webkit-linear-gradient(bottom, #b1bbc0 1%, #dfe5e7 100%) !important;
    background: linear-gradient(to top, #b1bbc0 1%, #dfe5e7 100%) !important;
    cursor: default !important;
}

.scaning-range-table-coll-button button:hover {
    background: -webkit-linear-gradient(bottom,#dfe5e7 1%, #ebf1f3 100%) !important;
    background: linear-gradient(to top,#dfe5e7 1%, #ebf1f3 100%) !important;
    border: 1px solid #2caeff !important;
}

.scaning-range-table-coll-button {
    width: 20px;
    align-self: flex-end;
    margin-bottom: 3px;
}

.min-max-fov-field {
    width: 50px !important;
    text-align: center !important;
}
.guide-container {
    position: absolute;
}

.tooltip-guide-box {
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 5px 5px;
    font-size: 11px;
    background: #f8f9c9;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    box-shadow: 0 3px 3px rgba(0,0,0,0.5);
    z-index: 10;
}


.tooltip-guide-patient-info {
    left: 350px;
    top: 15px;
}

.tooltip-guide-patient-info-full {
    left: 350px;
    top: 45px;
}

.tooltip-guide-save-series {
    left: -150px;
    top: 550px;
}

.tooltip-guide-prepare-scan {
    left: -150px;
    top: 550px;
}

.tooltip-guide-scan-button {
    left: 500px;
    top: 580px;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  opacity: 1;
}

input[type=number]::-webkit-outer-spin-button, 
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: inner-spin-button !important;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
}

#remove-padding {
    padding: 0 !important;
    width: 502px;
}

#remove-height {
  height: 275px !important;
}

.home-page input {
  border-radius: 0 !important;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  -ms-border-radius: 0 !important;
  -o-border-radius: 0 !important;
  color: #3c5c63 !important;
  font-size: 10px !important;
  padding-left: 4px !important;
}

.home-page input:focus {
  box-shadow: 0px 0px 0px 1px #2caeff !important;
}
.home-page input:-moz-read-only {
  box-shadow: none !important;
}
.home-page input:read-only {
  box-shadow: none !important;
}

.home-page label {
  color: #1b4049 !important;
  font-size: 9px !important;
  font-weight: 400 !important;
}

.first-grid-row>.navigation-section-column {
  width: 206px !important;
  padding: 0px 5px 0 5px !important;
}

.first-grid-row {
  height: 298px !important;
}

.rx-manager-column {
  border-top: 1px solid lightgray !important;
  border-left: 1px solid lightgray !important;
  border-right: 1px solid lightgray !important;
  width: 206px !important;
  padding: 0 !important;
  height: 295px;
}

.scan-timing-column {
  border: 1px solid lightgray !important;
  padding: 0 !important;
  width: 240px !important;
  height: 275px !important;
}

.patient-information-column {
  border-top: 1px solid lightgray !important;
  border-bottom: 1px solid lightgray !important;
  border-left: 1px solid lightgray !important;
  padding: 0 5px !important;
  width: 216px !important; 
}

.first-grid-row>.patient-position-block-column {
  border-top: 1px solid lightgray !important;
  border-bottom: 1px solid lightgray !important;
  border-right: 1px solid lightgray !important;
  padding: 0 !important;
  width: 526px !important;
}

.patient-position-block > .column {
  padding: 0 !important;
} 

.patient-position-column {
  width: 260px !important;
}

.autoview-column {
  width: 255px !important;
}


.prepare-to-scan-column {
  width: 206px !important;
  padding: 0 !important;
  text-align: center;
  border-bottom: 1px solid lightgray !important;
  border-right: 1px solid lightgray !important;
  border-left: 1px solid lightgray !important;
}

.prepare-to-scan-column button {
  width: 100% !important;
  height: 22px !important;
  border-radius: 5px;
  border-top: 1px solid lightgrey !important;
  border-bottom: 1px solid grey !important;
  border-left: 1px solid grey !important;
  border-right: 1px solid grey !important;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  background: -webkit-linear-gradient(bottom, #e7e6e6 1%, #ffffff 70%) !important;
  background: linear-gradient(to top, #e7e6e6 1%, #ffffff 70%) !important;
  font-weight: bold !important;
  color: #17323b !important;
  line-height: 0px !important;
  font-size: 12px;
  margin-top: 25px !important;
}

.prepare-to-scan-column button:hover {
  background: -webkit-linear-gradient(bottom, #e7e6e6 1%, #ffffff 70%) !important;
  background: linear-gradient(to top, #e7e6e6 1%, #ffffff 70%) !important;
  border: 1px solid #2caeff !important;
  cursor: default !important;
}

.prepare-to-scan-column button:disabled {
  background: -webkit-linear-gradient(bottom, #cccccc 1%, rgba(255,255,255,0.8) 100%) !important;
  background: linear-gradient(to top, #cccccc 1%, rgba(255,255,255,0.8) 100%) !important;
  color: grey !important;
  border-top: 1px solid lightgrey !important;
  border-bottom: 1px solid grey !important;
  border-left: 1px solid grey !important;
  border-right: 1px solid grey !important;
}


#remove-height-top {
  height: 185px !important;
}

#remove-height-bottom {
  width: 497px !important;
  height: 120px !important;
}

.additional-parameters-column {
  border: 1px solid lightgray !important;
  padding: 0 !important;
  width: 242px !important;
}

.acquisition-timing-column {
  border: 1px solid lightgray !important;
  width: 260px !important;
  height: 152px !important;
  padding: 0 !important;
}

.scaning-rage-block {
  border: 1px solid lightgray !important;
  padding: 0 !important;
  height: 123px;
  width: 502px !important;
}

.bottom-scan-parameters {
  border: 1px solid lightgray !important;
  padding: 0 !important;
  width: 742px !important;
  max-height: 50px !important;
}

.scan-params-row-buttons .scan-pause-button {
  width: 55px !important;
  height: 42px !important;
  margin-top: 2px;
  margin-right: 2px;
}

.scan-pause-button:disabled {
  width: 55px !important;
  height: 42px !important;
}

.bottom-scan-parameters button{
  height: 22px !important;
  padding: 10px !important;
  border-radius: 5px;
  border-top: 1px solid lightgrey !important;
  border-bottom: 1px solid grey !important;
  border-left: 1px solid grey !important;
  border-right: 1px solid grey !important;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  background: -webkit-linear-gradient(bottom, #e7e6e6 1%, #ffffff 100%) !important;
  background: linear-gradient(to top, #e7e6e6 1%, #ffffff 100%) !important;
  font-weight: bold !important;
  color: #17323b !important;
  line-height: 0px !important;
  font-size: 12px;
  align-self: flex-end;
}

.bottom-scan-parameters button:hover {
  background: -webkit-linear-gradient(bottom, #e7e6e6 1%, #ffffff 70%) !important;
  background: linear-gradient(to top, #e7e6e6 1%, #ffffff 70%) !important;
  border: 1px solid #2caeff !important;
  cursor: default !important;
}

.bottom-scan-parameters button:disabled {
  background: -webkit-linear-gradient(bottom, #cccccc 1%, rgba(255,255,255,0.8) 100%) !important;
  background: linear-gradient(to top, #cccccc 1%, rgba(255,255,255,0.8) 100%) !important;
  color: grey !important;
  border-top: 1px solid lightgrey !important;
  border-bottom: 1px solid grey !important;
  border-left: 1px solid grey !important;
  border-right: 1px solid grey !important;
}

.save-series-button {
  width: 98px !important;
  height: 22px !important;
}

.row {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.ui.center.aligned.header {
  font-size: 10px !important;
  margin-bottom: 5px !important;
  color: #f6f6e0 !important;
  font-family: Verdana, Geneva, Tahoma, sans-serif !important;
  font-weight: bold !important;
}

.ui.grid {
  margin: 0 auto !important;
  /* width: 948px !important; */
  
}

.field > div input {
  height: 22px !important;
}

.field>.selection.dropdown {
  line-height: 3px !important;
  height: 22px;
  min-height: 22px !important;
  max-width: 185px !important;
  width: 185px !important;
}

.row .field label {
  text-align: center;
}

.field-error {
  color: #9f3a38;
  float: left;
  margin-top: -13px;
  margin-bottom: 20px;
}
.form-grid {
  height: 100vh;
  overflow-y: scroll;
}


.register-form-grid-column, .login-form-grid-column {
  padding-top: 2% !important;
}

.register-form-grid-column, .login-form-grid-column {
  max-width: 450px;
}

.register-form-grid-column .ui.center.aligned.header {
  font-size: 2rem !important;
  font-weight: 700 !important;
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif !important;
  color: black !important;
  text-transform: none !important;
  margin: 2rem 0 1rem 0 !important;
}

.login-form-grid-column .ui.center.aligned.header{
  font-size: 2rem !important;
  font-weight: 700 !important;
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif !important;
  color: black !important;
  text-transform: none !important;
  margin: 1rem 0 1rem 0 !important;
}

.register-form-grid-column .field-error {
  margin-top: 0 !important;
}

.register-form-grid-column .ui.form .field {
  margin-top: 0.5rem !important;
  margin-bottom: 3rem !important;
  display: block !important;
}

.register-form-grid-column .field > label {
  margin-bottom: 0.5rem !important;
}

.register-form-grid-column .field > .selection.dropdown {
  height: 38px !important;
  width: 100% !important;
  max-width: 100% !important;
  clear: both !important;
  margin-left: 0 !important;
  line-height: 15px !important;
}

.register-form-grid-column .field > div input {
  height: 38px !important;
  width: 100% !important;
  clear: both !important;
}

.login-form.ui.form .field {
  margin-bottom: 14px !important;
}

.register-form-grid-column button {
  color: #0B333C !important
}

.register-form-grid-column .ui.message {
  font-size: 14px !important;
}

.restore {
  margin-top: 0 !important;
}

.sign-up {
  margin-bottom: 0 !important;
}

.error-style .rrt-title {  
  font-size: 18px !important;
}

.error-style .rrt-text {
  padding-top: 5px;
  font-size: 12px;
  line-height: 1.2;
}
.restore-page {
    /* padding-top: 10rem; */
}

.restore-block {
    width: 400px;
    margin: 0 auto;
    text-align: center;
    background: white;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.restore-block > h3 {
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
}

.restore-block > p {
    color: red;
    font-size: 12px;
    padding-top: 5px;
}

.input-restore {
    width: 300px;
    height: 40px !important;
    font-size: 14px !important;
}

.restore-submit { 
    display: block !important;
    width: 100px;
    margin: 14px auto 0 auto !important;
}

.restore-page .message {
    width: 400px;
    margin: 5px auto 0 auto;
    padding: 5px 10px;
}

.restore-page .message .header {
    margin-bottom: 10px;
}
.new-password-page {
    /* padding-top: 10rem; */
}

.new-password-block {
    width: 400px;
    margin: 0 auto;
    text-align: center;
    background: white;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.new-password-block > div {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.password-flex-item-1 {
    width: 100px;
    text-align: left;
    font-size: 0.9rem;
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
}

.new-password-block input {
    display: block;
    width: 250px;
    margin-bottom: 14px !important;
    height: 40px;
    font-size: 12px;
}

.new-password-block > button {
    width: 100px;
}

.new-password-block p {
    color: red;
    font-size: 12px;
}

.restore-submit { 
    display: block !important;
    width: 100px;
    margin: 14px auto 0 auto !important;
}

.new-password-page .message {
    width: 400px;
    margin: 5px auto 0 auto;
    padding: 5px 10px;
}

.new-password-page .message .header {
    margin-bottom: 10px;
}
