.patient-information-column .field {
    justify-content: space-between !important;
}

.patient-information-column .field label {
    width: 70px !important;
    flex: unset;
    padding-top: 5px;
    padding-left: 5px !important;
    line-height: 12px !important;
}

.patient-information-column .field .ui.input {
    margin: 0 !important;
    padding-right: 0 !important;
    width: 130px !important;
    flex: unset;
}

.patient-information-column input {
    width: 130px !important;
    border-radius: 0 !important;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    -ms-border-radius: 0 !important;
    -o-border-radius: 0 !important;
}

.patient-information-column img {
    margin-top: 2px;
}

.patient-information-column label {
    width: 42px;
}