.navigation-block {
    text-align: center;
}

.navigation-block button {
    background: none !important;
    font-size: 12px !important;
    color: #17323b !important;
    padding: 5px 18px !important; 
    margin-top: 5px !important;
    margin-bottom: 5px !important;
}

.navigation-block button:hover {
    background: #b2e1ff !important;
}



.admin-password-modal {
    width: 380px !important;
    height: 150px !important;
    background: #e2e2e2 !important;
    padding: 10px !important;
}

.admin-password-modal .header {
    padding: 0 !important;
    font-size: 12px !important;
    color: #17323b !important;
    border: none !important;
    background-color: #e2e2e2 !important;
}

.admin-password-modal .close.icon {
    width: auto !important;
    height: auto !important;
    font-size: 14px !important;
    margin-right: 10px !important;
    padding-top: 0 !important;
    margin-top: 10px !important;
}

.admin-password-modal .close.icon:hover {
    cursor: default !important;
}

.admin-password-modal .content {
    text-align: center;
    font-size: 11px !important;
    color: #1b4049;
    margin-top: 10px !important;
    padding: 15px 30px !important;
    height: 105px !important;
}

.admin-password-modal .content .admin-password-input-group {
    width: 100% !important;
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
}

.admin-password-modal .content .admin-password-input-group input {
    height: 25px;
    width: 230px;
    font-size: 12px;
    border-top: 1px solid lightslategray;
    border-bottom: 1px solid lightgray;
    border-right: 1px solid lightgray;
    border-left: 1px solid lightgray;
}

.admin-password-modal .content .admin-password-input-group input:focus {
    box-shadow: 0px 0px 0px 1px #2caeff !important;
    outline: none !important;
}

.admin-password-modal .content button {
    float: right !important;
    color: rgba(23, 50, 59, 0.9) !important;
    font-size: 12px;
    font-weight: 700;
    padding: 4px;
    width: 60px;
    background: linear-gradient(to top, #cccccc 1%, #ffffff 100%) !important;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.admin-password-modal .content button:hover {
    background: linear-gradient(to top, #cccccc 1%, #ffffff 70%) !important;
    border: 1px solid #2caeff !important;
    cursor: default !important;
}

.admin-password-input-notification {
    float: left !important;
    color: red;
}