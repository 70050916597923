 .plan-slices-dashboard {
    z-index: 2;
    height:  250px !important;
    width:  250px !important;
    position: absolute;
    border: 1px solid grey;
} 

.empty-image {
    overflow: hidden;
}

.plan-slices-slider-container {
    overflow: hidden;
    background: black;
}