.human-body-image {
  max-width: 140px !important;
  height: 270px !important;
}

#back-to-position-button {
  top: 262px;
  left: 175px;
  height: 24px;
  width: 75px !important;
  font-size: 11px;
  border-radius: 5px;
  border-top: 1px solid lightgrey !important;
  border-bottom: 1px solid grey !important;
  border-left: 1px solid grey !important;
  border-right: 1px solid grey !important;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  background: linear-gradient(to top, #e7e6e6 1%, #ffffff 100%) !important;
  font-weight: bold !important;
  color: #17323b !important;
}

#back-to-position-button:hover {
  background: linear-gradient(to top, #e7e6e6 1%, #ffffff 70%) !important;
  border: 1px solid #2caeff !important;
  cursor: default !important;
}

.human-body-parts button {
  background: none !important;
  font-size: 12px !important;
  position: absolute;
  left: 144px;
  width: 110px !important;
  color: #17323b !important;
  padding: 5px !important;
}

.human-body-parts button:hover {
  background: #b2e1ff !important;
}

.head-button {
  top: 22px;
}

.neck-button {
  top: 48px;
}

.chest-button {
  top: 69px;
}

.upper-extremity-button {
  top: 89px;
}

.abdomen-button {
  top: 108px;
}

.pelvis-button {
  top: 138px;
}

.lower-extremity-button {
  top: 200px;
}

.protocol-selection > .field {
  width: 185px !important;
  margin: 0 0 0 10px !important;
}

.protocol-selection .field .ui.input {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 15px !important;
  height: 30px;
  margin: 0 !important;
  flex: 0;
}

.protocol-selection {
  width: 260px !important;
  display: inline-flex !important;
  margin: 0 !important;
}

.protocol-selection .field .input input {
  width: 99px !important;
  flex-grow: 0 !important;
  border-radius: 0 !important;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  -ms-border-radius: 0 !important;
  -o-border-radius: 0 !important;
}

.popup-trigger-button {
  display: inline-block;
  margin-right: 6px !important;
  margin-left: 4px !important;
  min-width: 80px;
  height: 22px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  font-size: 12px;
  font-weight: 700;
  color: rgba(23, 50, 59, 0.9) !important;
  border-top: 1px solid lightgrey !important;
  border-bottom: 1px solid grey !important;
  border-left: 1px solid grey !important;
  border-right: 1px solid grey !important;
  background: linear-gradient(to top, #e7e6e6 1%, #ffffff 100%) !important;
}

.popup-trigger-button:hover {
  background: linear-gradient(to top, #e7e6e6 1%, #ffffff 70%) !important;
  border: 1px solid #2caeff !important;
  cursor: default !important;
}

.protocol-selection-modal {
  min-width: 170px !important;
  min-height: 300px !important;
  width: auto !important;
  border-left: 10px solid #e2e2e2 !important;
  border-right: 10px solid #e2e2e2 !important;
  border-bottom: 10px solid #e2e2e2 !important;
  top: 25px;
  right: 480px;
}

@media (min-height: 701px) {
  .protocol-selection-modal {
    top: 110px;
  }
}

.protocol-selection-modal .header {
  padding: 0 !important;
  font-size: 12px !important;
  text-align: center;
  text-transform: none !important;
  color: #17323b !important;
  border-bottom: none !important;
  background: #e2e2e2 !important;
  padding-top: 10px !important;
  border-radius: 0 !important;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  -ms-border-radius: 0 !important;
  -o-border-radius: 0 !important;
}

.protocol-selection-modal.ui.modal .close.icon {
  top: 1px !important;
  right: -12px !important;
  font-size: 1em !important;
}

.protocol-selection-modal .content {
  padding: 10px !important;
  border-top: 10px solid #e2e2e2;
}

.protocol-list {
  border: 1px solid rgba(0,0,0,0.2);
  min-height: 235px;
}

.protocol-list ul {
  list-style: none;
  padding: 0;
  margin: 0;
  text-indent: 5px;
}

.protocol-list li {
  font-size: 11px;
  margin-bottom: 5px;
  color: #1b4049;
}

.protocol-list li:hover {
  background: #b2e1ff;
  cursor: default;
}

.protocol-error-message {
  width: auto !important;
  height: 95px !important;
  padding: 20px 10px 10px 10px !important;
  background: rgba(124, 143, 153, 0.8) !important;
}

.protocol-error-message .content {
  width: 100% !important;
  height: 100% !important;
  text-align: center;
  border: 1px solid #dddddd !important;
  padding: 10px 20px 0 20px !important;
  border-radius: 0 !important;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  -ms-border-radius: 0 !important;
  -o-border-radius: 0 !important;
  background: #dddddd !important;
}

.protocol-error-message .content p {
  font-size: 11px !important;
}

.protocol-error-message .content button {
  height: 22px !important;
  width: 65px !important;
  font-size: 11px;
  font-weight: bold;
  background: linear-gradient(to top, #cecece 1%, #ffffff 100%) !important;
  border: 2px solid grey;
  border-radius: .28571429rem;
  -webkit-border-radius: .28571429rem;
  -moz-border-radius: .28571429rem;
  -ms-border-radius: .28571429rem;
  -o-border-radius: .28571429rem;
}

.protocol-error-message .content button:hover {
  background: white !important;
  border: 1px solid grey
}

.protocol-confirm-message {
  width: 195px !important;
  height: 95px !important;
  padding: 20px 10px 10px 10px !important;
  background: rgba(124, 143, 153, 0.8) !important;
}

.protocol-confirm-message .content {
  width: 100% !important;
  height: 100% !important;
  text-align: center;
  border: 1px solid #dddddd !important;
  padding: 10px 0 0 0 !important;
  border-radius: 0 !important;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  -ms-border-radius: 0 !important;
  -o-border-radius: 0 !important;
  background: #dddddd !important;
}

.protocol-confirm-message p {
  font-size: 11px !important;
}

.protocol-confirm-message button {
  height: 22px !important;
  width: 65px !important;
  font-size: 11px;
  font-weight: bold;
  background: linear-gradient(to top, #cecece 1%, #ffffff 100%) !important;
  border: 2px solid grey;
  border-radius: .28571429rem;
  -webkit-border-radius: .28571429rem;
  -moz-border-radius: .28571429rem;
  -ms-border-radius: .28571429rem;
  -o-border-radius: .28571429rem;
}

.protocol-confirm-message button:hover {
  background: white !important;
  border: 1px solid grey
}

.body-part-confirm-message {
  width: 275px !important;
  height: 95px !important;
  padding: 20px 10px 10px 10px !important;
  background: rgba(124, 143, 153, 0.8) !important;
}

.body-part-confirm-message .content {
  width: 100% !important;
  height: 100% !important;
  text-align: center;
  border: 1px solid #dddddd !important;
  padding: 10px 0 0 0 !important;
  border-radius: 0 !important;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  -ms-border-radius: 0 !important;
  -o-border-radius: 0 !important;
  background: #dddddd !important;
}

.body-part-confirm-message p {
  font-size: 11px !important;
}

.body-part-confirm-message button {
  margin-top: 3px;
  height: 22px !important;
  width: 65px !important;
  font-size: 11px;
  font-weight: bold;
  background: linear-gradient(to top, #cecece 1%, #ffffff 100%) !important;
  border: 2px solid grey;
  border-radius: .28571429rem;
  -webkit-border-radius: .28571429rem;
  -moz-border-radius: .28571429rem;
  -ms-border-radius: .28571429rem;
  -o-border-radius: .28571429rem;
}

.body-part-confirm-message button:hover {
  background: white !important;
  border: 1px solid grey
}