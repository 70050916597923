.coil-selection > .field {
    width: 214px !important;
    margin: 0 !important;
    padding-left: 32px !important;
  }
  
.coil-selection .field .ui.input {
  padding: 0 !important;
  height: 27px;
}
  
.coil-selection {
  width: 100% !important;
  display: inline-flex !important;
  margin: 0 !important;
}

.coil-selection .ui.input {
  margin: 0 !important;
  padding: 0 !important;
}

.coil-selection .field .input input {
  width: 147px !important;
  flex-grow: 0 !important;
  margin-left: 29px;
  border-radius: 0 !important;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  -ms-border-radius: 0 !important;
  -o-border-radius: 0 !important;
}

.coil-trigger-button {
  margin-left: 9px;
  margin-top: 2px;
  display: inline-block;
  width: 30px;
  height: 22px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  font-size: 12px;
  font-weight: 700;
  color: rgba(23, 50, 59, 0.9) !important;
  border-top: 1px solid lightgrey !important;
  border-bottom: 1px solid grey !important;
  border-left: 1px solid grey !important;
  border-right: 1px solid grey !important;
  background: linear-gradient(to top, #e7e6e6 1%, #ffffff 100%) !important;
}

.coil-trigger-button:hover {
  background: linear-gradient(to top, #e7e6e6 1%, #ffffff 70%) !important;
  border: 1px solid #2caeff !important;
  cursor: default !important;
}

.coils-modal {
  width: 650px !important;
  height: 500px !important;
  background: #e2e2e2 !important;
  padding: 5px 10px 10px 10px !important;
  /* iOS Safari fix for positioning: */
  position: relative !important;
  /* ------------------------------- */
}

.coils-modal .header {
  padding: 0 !important;
  border-bottom: none !important;
  font-size: 12px !important;
  color: #1b4049 !important;
  background: #e2e2e2 !important;
  margin-bottom: 10px !important;
}

.coils-modal .content {
  padding: 0 !important;
  text-align: center;
  height: 460px;
  overflow-y: auto; 
}

.coils-modal .content p {
  font-size: 12px !important;
  font-weight: bold;
  padding-top: 5px !important;
  margin-bottom: 0em !important;
}

.coil-image {
  height: 185px !important;
}

.coils-modal .close.icon {
  padding: 8px 0 0 0 !important;
  margin: 0 !important;
  font-size: 14px !important;
}

.coils-modal .close.icon:hover {
  cursor: default !important;
}

.coils-each-column {
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin: 5px !important;
  width: 195px !important;
}

.coils-each-column span {
  font-weight: bold;
}