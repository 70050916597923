input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  opacity: 1;
}

input[type=number]::-webkit-outer-spin-button, 
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: inner-spin-button !important;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
}

#remove-padding {
    padding: 0 !important;
    width: 502px;
}

#remove-height {
  height: 275px !important;
}

.home-page input {
  border-radius: 0 !important;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  -ms-border-radius: 0 !important;
  -o-border-radius: 0 !important;
  color: #3c5c63 !important;
  font-size: 10px !important;
  padding-left: 4px !important;
}

.home-page input:focus {
  box-shadow: 0px 0px 0px 1px #2caeff !important;
}
.home-page input:read-only {
  box-shadow: none !important;
}

.home-page label {
  color: #1b4049 !important;
  font-size: 9px !important;
  font-weight: 400 !important;
}

.first-grid-row>.navigation-section-column {
  width: 206px !important;
  padding: 0px 5px 0 5px !important;
}

.first-grid-row {
  height: 298px !important;
}

.rx-manager-column {
  border-top: 1px solid lightgray !important;
  border-left: 1px solid lightgray !important;
  border-right: 1px solid lightgray !important;
  width: 206px !important;
  padding: 0 !important;
  height: 295px;
}

.scan-timing-column {
  border: 1px solid lightgray !important;
  padding: 0 !important;
  width: 240px !important;
  height: 275px !important;
}

.patient-information-column {
  border-top: 1px solid lightgray !important;
  border-bottom: 1px solid lightgray !important;
  border-left: 1px solid lightgray !important;
  padding: 0 5px !important;
  width: 216px !important; 
}

.first-grid-row>.patient-position-block-column {
  border-top: 1px solid lightgray !important;
  border-bottom: 1px solid lightgray !important;
  border-right: 1px solid lightgray !important;
  padding: 0 !important;
  width: 526px !important;
}

.patient-position-block > .column {
  padding: 0 !important;
} 

.patient-position-column {
  width: 260px !important;
}

.autoview-column {
  width: 255px !important;
}


.prepare-to-scan-column {
  width: 206px !important;
  padding: 0 !important;
  text-align: center;
  border-bottom: 1px solid lightgray !important;
  border-right: 1px solid lightgray !important;
  border-left: 1px solid lightgray !important;
}

.prepare-to-scan-column button {
  width: 100% !important;
  height: 22px !important;
  border-radius: 5px;
  border-top: 1px solid lightgrey !important;
  border-bottom: 1px solid grey !important;
  border-left: 1px solid grey !important;
  border-right: 1px solid grey !important;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  background: linear-gradient(to top, #e7e6e6 1%, #ffffff 70%) !important;
  font-weight: bold !important;
  color: #17323b !important;
  line-height: 0px !important;
  font-size: 12px;
  margin-top: 25px !important;
}

.prepare-to-scan-column button:hover {
  background: linear-gradient(to top, #e7e6e6 1%, #ffffff 70%) !important;
  border: 1px solid #2caeff !important;
  cursor: default !important;
}

.prepare-to-scan-column button:disabled {
  background: linear-gradient(to top, #cccccc 1%, rgba(255,255,255,0.8) 100%) !important;
  color: grey !important;
  border-top: 1px solid lightgrey !important;
  border-bottom: 1px solid grey !important;
  border-left: 1px solid grey !important;
  border-right: 1px solid grey !important;
}


#remove-height-top {
  height: 185px !important;
}

#remove-height-bottom {
  width: 497px !important;
  height: 120px !important;
}

.additional-parameters-column {
  border: 1px solid lightgray !important;
  padding: 0 !important;
  width: 242px !important;
}

.acquisition-timing-column {
  border: 1px solid lightgray !important;
  width: 260px !important;
  height: 152px !important;
  padding: 0 !important;
}

.scaning-rage-block {
  border: 1px solid lightgray !important;
  padding: 0 !important;
  height: 123px;
  width: 502px !important;
}

.bottom-scan-parameters {
  border: 1px solid lightgray !important;
  padding: 0 !important;
  width: 742px !important;
  max-height: 50px !important;
}

.scan-params-row-buttons .scan-pause-button {
  width: 55px !important;
  height: 42px !important;
  margin-top: 2px;
  margin-right: 2px;
}

.scan-pause-button:disabled {
  width: 55px !important;
  height: 42px !important;
}

.bottom-scan-parameters button{
  height: 22px !important;
  padding: 10px !important;
  border-radius: 5px;
  border-top: 1px solid lightgrey !important;
  border-bottom: 1px solid grey !important;
  border-left: 1px solid grey !important;
  border-right: 1px solid grey !important;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  background: linear-gradient(to top, #e7e6e6 1%, #ffffff 100%) !important;
  font-weight: bold !important;
  color: #17323b !important;
  line-height: 0px !important;
  font-size: 12px;
  align-self: flex-end;
}

.bottom-scan-parameters button:hover {
  background: linear-gradient(to top, #e7e6e6 1%, #ffffff 70%) !important;
  border: 1px solid #2caeff !important;
  cursor: default !important;
}

.bottom-scan-parameters button:disabled {
  background: linear-gradient(to top, #cccccc 1%, rgba(255,255,255,0.8) 100%) !important;
  color: grey !important;
  border-top: 1px solid lightgrey !important;
  border-bottom: 1px solid grey !important;
  border-left: 1px solid grey !important;
  border-right: 1px solid grey !important;
}

.save-series-button {
  width: 98px !important;
  height: 22px !important;
}

.row {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.ui.center.aligned.header {
  font-size: 10px !important;
  margin-bottom: 5px !important;
  color: #f6f6e0 !important;
  font-family: Verdana, Geneva, Tahoma, sans-serif !important;
  font-weight: bold !important;
}

.ui.grid {
  margin: 0 auto !important;
  /* width: 948px !important; */
  
}

.field > div input {
  height: 22px !important;
}

.field>.selection.dropdown {
  line-height: 3px !important;
  height: 22px;
  min-height: 22px !important;
  max-width: 185px !important;
  width: 185px !important;
}

.row .field label {
  text-align: center;
}
