.empty-image, .empty-image > img {
  width: 250px !important;
  height: 250px !important;
  background-color: black;
  margin: 0 auto;
}

.carousel__slide-focus-ring {
  position: absolute;
  top: 0px !important;
  right: 0px !important;
  bottom: 0px !important;
  left: 0px !important;
  outline-width: 0px !important;
  outline-style: solid;
  outline-color: none !important;
}

.slide-white-overlay {
  position: absolute;
  top: 0;
  /* width: 250px;
  height: 250px; */
  width: 100%;
  height: 100%;
  background:white;
  filter: opacity(0);
  -webkit-filter: opacity(0);
}

@supports (-ms-ime-align:auto) { 
.slide-white-overlay {
  opacity: 0;
}
}

.autoview-button-block {
  margin-left: -3px;
  margin-top: 4px;
  margin-bottom: 3px;
}

.autoview-button-block button {
    height: 22px;
    width: 56px;
    font-size: 12px !important;
    margin: 0 5px !important;
    padding: 0 !important;
    border-radius: 5px !important;
    -webkit-border-radius: 5px !important;
    -moz-border-radius: 5px !important;
    -ms-border-radius: 5px !important;
    -o-border-radius: 5px !important;
    border-top: 1px solid lightgrey !important;
    border-bottom: 1px solid grey !important;
    border-left: 1px solid grey !important;
    border-right: 1px solid grey !important;
    background: linear-gradient(to top, #e7e6e6 1%, #ffffff 100%) !important;
    color: rgba(23, 50, 59, 0.9) !important;
    font-weight: 700 !important;
    line-height: 22px !important;
}

.autoview-button-block button:hover {
  background: linear-gradient(to top, #e7e6e6 1%, #ffffff 70%) !important;
  border: 1px solid #2caeff !important;
  cursor: default !important;
}

.autoview-button-block button:disabled {
  background: linear-gradient(to top, #cccccc 1%, rgba(255,255,255,0.8) 100%) !important;
  color: grey !important;
  border-top: 1px solid lightgrey !important;
  border-bottom: 1px solid grey !important;
  border-left: 1px solid grey !important;
  border-right: 1px solid grey !important;
}

.autoview-button-block .ui.dropdown, .empty .ui.drowdowns  {
  background: linear-gradient(to top, #e7e6e6 1%, #ffffff 100%) !important;
  float: right !important;
  margin-right: 1px !important;
  height: 22px !important;
  min-height: 22px !important;
  width: 56px !important;
  color: rgba(23, 50, 59, 0.9) !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  padding: 0 !important;
  padding-left: 10px !important;
  line-height: 20px !important;
  border-top: 1px solid lightgrey !important;
  border-bottom: 1px solid grey !important;
  border-left: 1px solid grey !important;
  border-right: 1px solid grey !important;
}

.autoview-button-block .ui.dropdown:hover, .empty .ui.drowdowns:hover {
  background: linear-gradient(to top, #e7e6e6 1%, #ffffff 70%) !important;
  border: 1px solid #2caeff !important;
  cursor: default;
}

.autoview-button-block .ui.disabled.dropdown , .empty .ui.disabled.dropdown {
  background: linear-gradient(to top, #cccccc 1%, #ffffff 100%) !important;
  opacity: 0.8;
}

.autoview-button-block .ui.selection.dropdown .item, .empty .ui.selection.dropdown .item {
  height: 22px !important;
  border: none !important;
  padding: 0 !important;
  padding-left: 10px !important;
  line-height: 22px !important;
  font-size: 12px !important;
}

.autoview-button-block .ui.selection.dropdown .active.selected.item, .empty .ui.selection.dropdown .active.selected.item {
  height: 22px !important;
  border: none !important;
  padding: 0 !important;
  padding-left: 10px !important;
  background: #7fceff !important;
  font-size: 12px !important;
}

.autoview-button-block .ui.dropdown .item:hover, .empty .ui.drowdowns .item:hover {
  background: #b2e1ff !important;
  cursor: default !important;
}


.autoview-button-block .ui.dropdown>.dropdown.icon, .empty .ui.drowdowns>.dropdown.icon {
  padding: 0 !important;
  padding-right: 3px !important;
  padding-left: 5px !important;
  font-size: 14px !important;
  margin-top: -10px !important;
  height: 18px !important;
  border-left: 1px solid rgba(23, 50, 59, 0.3) !important;
}

.autoview-button-block .ui.dropdown:hover>.dropdown.icon, .empty .ui.drowdowns:hover>.dropdown.icon {
  border-left: 1px solid #2caeff !important;
}

.autoview-button-block .ui.dropdown>.dropdown.icon:hover, .empty .ui.drowdowns>.dropdown.icon:hover {
  cursor: default;
}

.empty button {
  background: linear-gradient(to top, #cccccc 1%, rgba(255,255,255,0.8) 100%) !important;
  color: grey !important;
}

.empty button:hover {
  border-top: 1px solid lightgrey !important;
  border-bottom: 1px solid grey !important;
  border-left: 1px solid grey !important;
  border-right: 1px solid grey !important;
  background: linear-gradient(to top, #cccccc 1%, rgba(255,255,255,0.8) 100%) !important;
}

.autoview-column .autoview-container .carousel .carousel__slider {
  width: 250px !important;
  margin: 0 auto !important;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.autoview-column .autoview-container .carousel .carousel__slider-tray-wrapper {
  width: 250px !important;
  height: 250px !important;
  background: black !important;
}
