.rx-manager {
    height: 295px !important;
    /* width: 204px; */
}

.rx-manager .ui.buttons {
    justify-content: space-around;
    width: 210px;
}

.table-body {
    max-height: 215px !important;
    /* overflow-y: auto; */
    margin: 32px auto 0 auto;
    max-width: 204px !important;
}

.table-body-empty {
    overflow-y: hidden !important;
}

.header-number {
    height: 25px; 
    width: 22px;
    display: inline-block;
    text-align: center;
    position: absolute;
    top: 53px;
    left: 0px;
    background: linear-gradient(to top, #cecece 1%, #ffffff 50%) !important;
    font-weight: bold !important;
    font-size: 12px;
    color: rgba(23, 50, 59, 0.9) !important;
    line-height: 23px;
    border-right: 1px solid lightgrey;
}
.header-title {
    height: 25px; 
    width: 182px;
    display: inline-block;
    text-align: left;
    position: absolute;
    top: 53px;
    left: 22px;
    background: linear-gradient(to top, #cecece 1%, #ffffff 50%) !important;
    font-weight: bold !important;
    font-size: 12px;
    color: rgba(23, 50, 59, 0.9) !important;
    line-height: 23px;
    padding-left: 5px;
}

.rx-manager-series-table {
    margin-top: 5px !important;
    border-radius: 0 !important;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    -ms-border-radius: 0 !important;
    -o-border-radius: 0 !important;
}
 
.rx-manager-series-table .table-number {
    width: 20px !important;
    text-align: center !important;
    padding-left: 0 !important;
    color: #3c5c63 !important;
}

.rx-manager-series-table tbody tr td {
    height: 25px !important;
    padding: 0 0 0 5px !important;
    text-align: left !important;
    color: #3c5c63 !important;
}

.rx-manager-series-table .table-serie-body tr:hover {
    background: #b2e1ff !important;
    cursor: default;
}

.rx-manager-series-table tbody tr:nth-child(odd) {
    background: #f7f7f7;
}

.rx-manager-series-table.ui.table tr.active {
    background: #7fceff !important;
}

.rx-manager .buttons {
    margin-top: 3px;
    width: 100% !important;
    display: flex !important;
    justify-content: center;
}
.rx-manager .buttons button {
    height: 24px;
    width: 70px;
    font-size: 12px;
    margin: 0 5px;
    padding: 0;
    border-radius: 5px !important;
    -webkit-border-radius: 5px !important;
    -moz-border-radius: 5px !important;
    -ms-border-radius: 5px !important;
    -o-border-radius: 5px !important;
    border-top: 1px solid lightgrey;
    border-bottom: 1px solid grey;
    border-left: 1px solid grey;
    border-right: 1px solid grey;
    background: linear-gradient(to top, #b1bbc0 1%, #dfe5e7 100%);
    color: rgba(23, 50, 59, 0.9);
    font-weight: 700;
    font-size: 13px;
}

.rx-manager .end-exam-button {
    width: 86px !important;
}

.rx-manager .buttons button:hover {
    background: linear-gradient(to top,#dfe5e7 1%, #ebf1f3 100%);
    cursor: default;
    border: 1px solid #2caeff;
}

.rx-manager .active {
    background-color: skyblue;
}