.add-params {
    width: 237px !important;
    height: 150px !important;
    color: #1b4049 !important;
    margin: 0 auto !important;
}

.add-params .ui.button, .add-params .ui.button:disabled{
    width: 53px;
    height: 53px;
    padding: 0;
    margin: 0 !important;
    border-radius: 5px !important;
    -webkit-border-radius: 5px !important;
    -moz-border-radius: 5px !important;
    -ms-border-radius: 5px !important;
    -o-border-radius: 5px !important;
    border-top: 1px solid lightgrey;
    border-bottom: 1px solid grey;
    border-left: 1px solid grey;
    border-right: 1px solid grey;
    background: linear-gradient(to top, #b1bbc0 1%, #dfe5e7 100%) !important;
    cursor: default !important;

}

.add-params .button-caption {
    text-align: center;
    padding: 7px 0;
    font-size: 9px
}

.add-params .ui.grid>.row>.column {
    padding: 0!important;
    width: 53px !important;
    margin-left: 3px;
    margin-right: 3px;
}

.add-params .button img {
    margin-top: -1px;
    width: 52px;
    max-height: 52px;
}

.image-container {
    /* position: relative; */
    width: auto;
    height: auto !important;
}

.image-container img {
    width: 100%;
    position: relative;
}

.button-center {
    margin: 10px auto;
}

.of-slices-input {
    width: 60px;
    margin-left: 10px;
}

.buttom-buttons {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.buttom-buttons .button {
    margin: 5px 15px;
}

.buttons-center .button {
    margin: 5px 15px;
}


.plan-slices-modal, .plan-slices-modal-moved {
    width: 700px !important;
    height: 535px !important;
    background: #98a9b1 !important;
    padding: 5px 10px 10px 10px !important;
    position: relative !important;
    /* top: -50px; */
}

.plan-slices-modal .header, .plan-slices-modal-moved .header {
    padding: 0 !important;
    margin-bottom: 5px !important;
    background: #98a9b1 !important;
    font-size: 12px !important;
    color: #0c343d !important;
    border-bottom: none !important;
}

.plan-slices-modal .close.icon, .plan-slices-modal-moved .close.icon {
    padding: 5px 20px 0 0 !important;
    margin: 0 !important;
    font-size: 14px !important;
    height: 14px !important;
    width: 14px !important;
}

.plan-slices-modal .close.icon:hover, .plan-slices-modal-moved .close.icon:hover {
    cursor: default !important;
}

.plan-slices-modal .content, .plan-slices-modal-moved .content {
    padding: 0 !important;
}

.plan-slices-modal-content-block {
    display: flex;
}

.plan-slices-modal-content {
    background: #98a9b1
}

.plan-slices-modal-content-block .button-block-item {
    width: 365px;
    max-width: 365px;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    color: #0c343d;
    padding-top: 2px;
}

.viewports-block {
    width: 1020px;
    display: flex;
    flex-wrap: wrap;
}

.start-end-table-cell {
    border: 1px solid #576469;
    text-align: center;
    padding: 0 10px;
    font-size: 10px;
    font-weight: bold;
}

.graphic-rx-bottom {
    padding: 5px 0;
    /* margin-bottom: 5px; */
    border-top: none !important;
    border-bottom: none !important;
    border-right: none !important;
    width: 250px;
    height: 55px;
    margin-left: 0px;
    display: flex;
    justify-content: space-between
}

.graphic-rx-bottom .field {
    width: 50px;
    margin: 0;
    height: 20px;
}

.graphic-rx-bottom .field .ui.input>input{
    width: 35px;
    height: 20px;
    font-size: 12px;
    padding: 0;
    text-align: center
} 

.vieport-part-buttons > div {
    border: 1px solid gray;
    border-right: none;
}

.vieport-part-buttons-half {
    width: 125px;
    height: 205px;
    text-align: center;
    /* display: flex; */
}

.vieport-part-buttons-half.input-side {
    padding-right: 5px;
}

.vieport-part-buttons-half.input-side .td-title {
    font-size: 10px;
    text-align: right;
}

.vieport-part-buttons-half.input-side .td-input input {
    width: 50px;
    height: 20px;
    margin: 0 auto;
    padding: 0;
    padding-left: 5px;
    font-size: 10px;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
}

.vieport-part-buttons-half.input-side .td-input .field {
    margin: 0;
    height: 21px;
}

.input-side label {
    margin-bottom: 0;
    font-size: 10px;
    text-align: right;
    margin-right: 5px;
}

.td-input input[type='number']::-webkit-inner-spin-button, 
.td-input input[type='number']::-webkit-outer-spin-button {  
   -webkit-appearance: textfield;
   opacity: 1;
   /* height: 20px; */
   -moz-appearance:textfield;
}

.vieport-part-buttons {
    width: 250px;
    height: 250px;
    display: flex;
    flex-wrap: wrap;
}

.button-block-item button {
    font-size: 12px;
    font-weight: bold;
    color: #0c343d;

    border-top: 1px solid lightgrey !important;
    border-bottom: 1px solid grey !important;
    border-left: 1px solid grey !important;
    border-right: 1px solid grey !important;
    background: linear-gradient(to top, #e7e6e6 1%, #ffffff 100%) !important;
    border-radius: .28571429rem;
    -webkit-border-radius: .28571429rem;
    -moz-border-radius: .28571429rem;
    -ms-border-radius: .28571429rem;
    -o-border-radius: .28571429rem;
}

.button-block-item button:hover {
    background: linear-gradient(to top, #e7e6e6 1%, #ffffff 70%) !important;
    border: 1px solid #2caeff !important;
    cursor: default !important;
}

.button-block-item button:disabled {
    background: linear-gradient(to top, #e7e6e6 1%, #ffffff 100%) !important;
    color: rgba(12, 52, 61, 0.3) !important;
    border-top: 1px solid rgba(211, 211, 211, 0.5) !important;
    border-bottom: 1px solid rgba(128, 128, 128, 0.7) !important;
    border-left: 1px solid rgba(128, 128, 128, 0.5) !important;
    border-right: 1px solid rgba(128, 128, 128, 0.5) !important;
}

.button-block-item-main-buttons {
    width: 170px;
    height: 22px;
    /* margin-top: 3px; */
    /* margin-bottom: 2px; */
}

.button-box-block {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.buttons-box {
    width: 85px;
    height: 45px;
    border-radius: 0 !important;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    -ms-border-radius: 0 !important;
    -o-border-radius: 0 !important;
}

.button-block-item input[type="text"] {
    margin-left: 10px;
    width: 30px;
    height: 22px;
    padding: 0 0 0 2px;
    font-size: 10px;
    font-weight: bold;
    color: #0c343d;
    line-height: 22px;

    border-top: 1px solid black;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
}

.button-block-item .rotation-buttons {
    width: 40px;
    height: 22px;
    margin: 2px 3px;
}

.button-block-item .text-remark {
    font-weight: normal;
    font-size: 10px;
    width: 160px;
}

.sat-rx-buttons {
    width: 55px;
    height: 30px;
    margin: 15px 3px 3px 3px;
    font-size: 12px;
    font-weight: bold;
    color: #0c343d;

    border-top: 1px solid lightgrey !important;
    border-bottom: 1px solid grey !important;
    border-left: 1px solid grey !important;
    border-right: 1px solid grey !important;
    background: linear-gradient(to top, #e7e6e6 1%, #ffffff 100%) !important;
    border-radius: .28571429rem;
    -webkit-border-radius: .28571429rem;
    -moz-border-radius: .28571429rem;
    -ms-border-radius: .28571429rem;
    -o-border-radius: .28571429rem;
}

.sat-rx-buttons:hover {
    background: linear-gradient(to top, #e7e6e6 1%, #ffffff 70%) !important;
    border: 1px solid #2caeff !important;
    cursor: default !important;
}

.sat-rx-buttons:disabled {
    background: linear-gradient(to top, #e7e6e6 1%, #ffffff 100%) !important;
    color: rgba(12, 52, 61, 0.3) !important;
    border-top: 1px solid rgba(211, 211, 211, 0.5) !important;
    border-bottom: 1px solid rgba(128, 128, 128, 0.7) !important;
    border-left: 1px solid rgba(128, 128, 128, 0.5) !important;
    border-right: 1px solid rgba(128, 128, 128, 0.5) !important;
}

.plan-slices-slider-container, .plan-slices-slider-container img {
    width: 250px;
    height: 250px;
}

.fat-water-list {
    list-style: none;
    text-align: left;
    margin-left: 0;
    padding-left: 5px;
    color: #0c343d;
    font-weight: bold;
}

.fat-water-list input {
    margin-right: 8px;
    position: relative;
    top: 2px;
}

.plan-slices-image-button-block {
    margin-top: 3px;
    font-size: 12px;
    font-weight: bold;
    color: #0c343d;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.plan-slices-image-button-block button {
    font-size: 12px;
    font-weight: bold;
    color: #0c343d;

    border-top: 1px solid lightgrey !important;
    border-bottom: 1px solid grey !important;
    border-left: 1px solid grey !important;
    border-right: 1px solid grey !important;
    background: linear-gradient(to top, #e7e6e6 1%, #ffffff 100%) !important;
    border-radius: .28571429rem;
    -webkit-border-radius: .28571429rem;
    -moz-border-radius: .28571429rem;
    -ms-border-radius: .28571429rem;
    -o-border-radius: .28571429rem;
}

.plan-slices-image-button-block button:hover {
    background: linear-gradient(to top, #e7e6e6 1%, #ffffff 70%) !important;
    border: 1px solid #2caeff !important;
    cursor: default !important;
}

.plan-slices-image-button-block button:disabled {
    background: linear-gradient(to top, #e7e6e6 1%, #ffffff 100%) !important;
    color: rgba(12, 52, 61, 0.3) !important;
    border-top: 1px solid rgba(211, 211, 211, 0.5) !important;
    border-bottom: 1px solid rgba(128, 128, 128, 0.7) !important;
    border-left: 1px solid rgba(128, 128, 128, 0.5) !important;
    border-right: 1px solid rgba(128, 128, 128, 0.5) !important;
}

.accept-button {
    /* width: 115px !important; */
    height: 22px;
}

.change-image-block {
    margin-bottom: 115px;
}

.button-change-image {
    width: 30px;
    height: 30px;
    margin: 5px;
}

.copy-rx-button {
    width: 114px;
    display: inline-block;
}

.ui.button.dropdown-block-rx, .copy-rx-button {
    /* margin-top: 5px; */
    width: 170px;
    text-align: center;
    margin-bottom: 5px;
    height: 22px;
    line-height: 0px; 
    font-size: 13px;
    font-weight: bold;
    color: #0c343d;

    border-top: 1px solid lightgrey !important;
    border-bottom: 1px solid grey !important;
    border-left: 1px solid grey !important;
    border-right: 1px solid grey !important;
    background: linear-gradient(to top, #e7e6e6 1%, #ffffff 100%) !important;
    border-radius: .28571429rem;
    -webkit-border-radius: .28571429rem;
    -moz-border-radius: .28571429rem;
    -ms-border-radius: .28571429rem;
    -o-border-radius: .28571429rem;
}

.ui.button.dropdown-block-rx:hover {
    background: linear-gradient(to top, #e7e6e6 1%, #ffffff 70%) !important;
    border: 1px solid #2caeff !important;
    cursor: default !important;
}

.ui.button.dropdown-block-rx:disabled {
    background: linear-gradient(to top, #e7e6e6 1%, #ffffff 100%) !important;
    color: rgba(12, 52, 61, 0.3) !important;
    border-top: 1px solid rgba(211, 211, 211, 0.5) !important;
    border-bottom: 1px solid rgba(128, 128, 128, 0.7) !important;
    border-left: 1px solid rgba(128, 128, 128, 0.5) !important;
    border-right: 1px solid rgba(128, 128, 128, 0.5) !important;
}

.ui.button.dropdown-block-rx .item {
    height: 22px !important;
    line-height: 22px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.dropdown-block-rx .item:hover {
    background: #b2e1ff !important
}
.dropdown-block-rx .active.selected.item {
    background: #7fceff !important;
    height: 22px !important;
    line-height: 22px !important;
}

.dropdown-block-rx > .visible.menu {
    max-height: 200px;
    overflow: hidden;
    overflow-y: auto;
}

.plan-slices-image-button-block .display-buttons {
    width: 70px;
    height: 40px;
}

.plan-slices-image-button-block .zoom-input-block {
    text-align: center;
}

.zoom-input-block input[type='range'] {
    width: 145px;
    -webkit-appearance: none;
    background: transparent;
}

.zoom-input-block input[type=range]:focus {
    outline: none;
}

.zoom-input-block input[type='range']::-webkit-slider-runnable-track {
    width: 145px;
    height: 5px;
    background: #ddd;
    border: none;
    border-radius: 3px;
}

.zoom-input-block input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: grey;
    margin-top: -5px;
}

.zoom-input-block input[type='range']::-moz-range-track { 
    width: 145px;
    height: 5px;
    background: #ddd;
    border: none;
    border-radius: 3px;
}

.zoom-input-block input[type=range]::-moz-range-thumb {
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: grey;
}

.zoom-input-block input[type=range]:-moz-focusring{
    outline: 1px solid white;
    outline-offset: -1px;
}

.zoom-input-block input[type=range]:focus::-moz-range-track {
    outline: none;
}

.zoom-input-block input[type='range']::-ms-track { 
    width: 145px;
    height: 5px;
    background: transparent;
    border-color: transparent;
    border-width: 6px 0;
    color: transparent;
}

.zoom-input-block input[type=range]::-ms-fill-lower {
    background: #ccc;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.zoom-input-block input[type=range]::-ms-fill-upper {
    background: #ddd;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.zoom-input-block input[type=range]::-ms-thumb {
    border: none;
    height: 16px;
    width: 16px;
    margin-bottom: -5px;
    border-radius: 50%;
    background: grey;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.zoom-input-block input[type=range]:focus::-ms-fill-upper {
    background: #ccc;
}

.zoom-input-block p {
    text-align: left;
    padding-left: 18px;
    margin-top: 5px;
}

@supports (-ms-ime-align: auto) {
.zoom-input-block p {
    text-align: left;
    padding-left: 18px;
    margin-top: -8px;
}
}

.plan-slices-image-button-block .prior-next-block {
    width: 90px;
    text-align: center;
}

.plan-slices-image-button-block .prior-next-block button {
    width: 40px;
    height: 22px;
    margin: 0 2px 10px 2px;
}

.plan-slices-image-button-block .prior-next-block span {
    color: rgba(12, 52, 61, 0.3);
}

.plan-slices-image-button-block .ui.dropdown {
    background: linear-gradient(to top, #e7e6e6 1%, #ffffff 100%) !important;
    float: right !important;
    margin-right: 1px !important;
    height: 22px !important;
    min-height: 22px !important;
    width: 56px !important;
    color: rgba(23, 50, 59, 0.9) !important;
    font-size: 12px !important;
    font-weight: 700 !important;
    padding: 0 !important;
    padding-left: 10px !important;
    line-height: 20px !important;
    border-top: 1px solid lightgrey !important;
    border-bottom: 1px solid grey !important;
    border-left: 1px solid grey !important;
    border-right: 1px solid grey !important;
}

.plan-slices-image-button-block .ui.dropdown:hover {
    background: linear-gradient(to top, #e7e6e6 1%, #ffffff 70%) !important;
    border: 1px solid #2caeff !important;
    cursor: default;
}

.plan-slices-image-button-block .ui.selection.dropdown .item {
    height: 22px !important;
    border: none !important;
    padding: 0 !important;
    padding-left: 10px !important;
    line-height: 22px !important;
    font-size: 12px !important;
}

.plan-slices-image-button-block .ui.selection.dropdown .active.selected.item {
    height: 22px !important;
    border: none !important;
    padding: 0 !important;
    padding-left: 10px !important;
    background: #7fceff !important;
    font-size: 12px !important;
}

.plan-slices-image-button-block .ui.dropdown .item:hover {
    background: #b2e1ff !important;
    cursor: default !important;
}

.plan-slices-image-button-block .ui.dropdown>.dropdown.icon {
    padding: 0 !important;
    padding-right: 3px !important;
    padding-left: 5px !important;
    font-size: 14px !important;
    margin-top: -10px !important;
    height: 18px !important;
    border-left: 1px solid rgba(23, 50, 59, 0.3) !important;
}

.plan-slices-image-button-block .ui.dropdown:hover>.dropdown.icon {
    border-left: 1px solid #2caeff !important;
}

.plan-slices-image-button-block .ui.dropdown>.dropdown.icon:hover {
    cursor: default;
}

.autoview-graphic-rx {
    width: 300px;
    height: 300px;
    position: absolute;
    /* top: 0; */
    /* left: -302px; */
    z-index: 100;
}

.autoview-graphic-rx .close.icon {
    padding-top: 0px !important;
    padding-right: 0px !important; 
    margin: 0;
    font-size: 14px;
    height: max-content;
    width: max-content;
}

.graphic-rx-autoview-header {
    border-bottom: none !important;
    margin-bottom: 0 !important;
    background: #98a9b1 !important;
    padding: 3px 10px !important;
    color: rgba(23, 50, 59, 0.9) !important;
    font-size: 13px !important;
    font-weight: bold;
    display: flex;
    justify-content: space-between
}

.autoview-graphic-rx > .content {
    padding: 0px 10px 10px 10px !important;
    background: #98a9b1 !important;
}

.slices-input .ui.input {
    width: 25px !important;
    /* height: 20px !important; */
    padding: 0 !important;
}

.slices-input .ui.input input {
    height: 20px !important;
    line-height: 20px !important;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
}

.rx-slide-white-overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background:white;
    opacity: 0;
    z-index: 0;
}

.plan-slices-modal-moved {
    position: relative !important;
	left: -250px;
}

@media (min-height:1023px) and (max-height: 1025px) {
.autoview-graphic-rx {
    width: 250px !important;
    height: 250px !important;
}
.plan-slices-modal-moved {
	left: -155px;
}
}

@media (max-width: 1025px) {
.autoview-graphic-rx {
    width: 250px !important;
    height: 250px !important;
}
.plan-slices-modal-moved {
	left: -155px;
}
}