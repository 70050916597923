body {
  overflow: hidden;
  background: #98a9b1 !important;
  font-size: 9px !important;
  line-height: 1em !important;
  display: flex; 
  flex-direction: column;
  justify-content: center;
}

:focus {
  outline: none !important;
}

.dimmed.dimmable > :not(.dimmer) {
  -webkit-filter: blur(1px);
  filter: blur(1px);
}

.dimmed.dimmable > .tooltip-mri {
  filter: blur(0px) !important;
  -webkit-filter: blur(0px) !important;
}

h4 {
  margin-top: 0 !important;
}

.ui.celled.grid>.row>.column {
  padding: 0 1em !important;
}

.home-page .ui .header {
  color: lightgoldenrodyellow !important;
  text-transform: uppercase;
  font-weight: 600;
}

.home-page .ui .table .header {
  color: black !important;
}
.modal .header {
  color: gray !important;
}

.dimmer {
  background-color: rgba(221,221,221, 0.5) !important;
  transition: none !important;
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -ms-transition: none !important;
  -o-transition: none !important;
  animation: none !important;
  -webkit-animation: none !important;
}

.equal.fields .field {
  width: 100px !important;
}
.equal.fields>.field input {
  width: 100% !important;
}

.ui.form .field {
  margin: 0 !important;
}

.ui.center.aligned.header {
  margin: 0 !important;
}

.field {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 5px 0;
}

.field .ui.dropdown {
  width: 100%;
  margin: 0 7px 5px 7px;
  flex: 1 1 75%;
  height: 31px;
}

.home-page {
  width: 948px !important;
  /* align-self: center; */
}

.home-page .field .ui.input {
  width: 100%;
  margin: 0 5px;
  flex: 1 1 75%;
  height: 27px;
  padding: 2px !important;
}

.home-page .field .input input {
  width: 100% !important;
}

.field label {
  display: block;
  flex: 1 1 25%;
}

label {
  text-align: left;
  color: #3d494d !important;
  display: block;
  margin: 0 0 .28571429rem 0;
  font-size: .9em;
  font-weight: 700;
  text-transform: none;
}

.ui.modal .close.icon {
  top: 0 !important;
  right: 0 !important;
  color: #000000 !important;
}

.modal-scan-header .header {
  padding: none !important;
}