.scan-params-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 46px;
  color: #1b4049 !important;
}

.rx-scan-time {
  outline: none;
}

.rx-scan-time-bordered {
  outline: 2px solid #cd3142;
}


.scan-params-row-item > div:first-child {
  margin-top: 5px;
  margin-bottom: 15px;
}

.modal-scan-buttons {
  text-align: center !important;
  padding: 5px 0 0 0 !important;
}

.modal-scan-header.header {
  font-size: 12px !important;
  font-weight: 700 !important;
  color: black !important;
  text-transform: none;
  padding: inherit !important;
  background: #e2e2e2 !important;
  border: none !important;
  border-radius: 0 !important;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  -ms-border-radius: 0 !important;
  -o-border-radius: 0 !important;
  padding-bottom: 7px !important;
  padding-top: 3px !important;
  padding-left: 2px !important;
  color: #17323b !important;
}

.modal-scan-buttons > button {
  height: 23px;
  width: 102px;
  font-size: 11px;
  margin-right: 5px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  background: linear-gradient(to top, #e7e6e6 1%, #ffffff 80%) !important;
  font-weight: bold !important;
  color: #17323b !important;
}

.modal-scan-buttons > button:hover {
  background: linear-gradient(to top, #e7e6e6 1%, #ffffff 100%) !important;
  cursor: pointer !important;
}

.modal-scan-pause {
  border-top: 0px solid #e2e2e2 !important;
  border-bottom: 10px solid #e2e2e2 !important;
  border-left: 10px solid #e2e2e2 !important;
  border-right: 10px solid #e2e2e2 !important;
  width: 300px !important;
  height: 70px !important;
}

.zero-scan-time {
  color: #c72a2b;
}

.incorrect-time-popup {
  background: #c72a2b !important;
  border: 1px solid #c72a2b !important;
  color: white !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  top: 584px !important;
  right: 594px !important;
}

.incorrect-time-popup::before {
  background: #c72a2b !important;
  border: 1px solid #c72a2b !important;
}