.scan-timing-table-row {
    display: flex;
    align-items: center;
    justify-content: space-around;
    text-align: center;
    color: #1b4049 !important;
}

.disabled-row {
    color: lightgray !important;
}

.scan-timing-table-coll {
    width: 30px;
    font-size: 9px;
    margin-bottom: 5px;
    line-height: 24px;
    text-align: left !important;
}

.scan-timing-table-coll-input-label {
    width: 50px;
    text-align: center;
    font-size: 9px;
}

.scan-timing-table-coll-input {
    width: 60px;
}

.scan-timing-table-coll-input input {
    padding-left: 5px !important;
    padding-right: 1px !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    font-size: 10px;
}

.scan-timing-table-coll-input .field {
    font-size: 12px;
    width: 60px;
    margin: 0 !important;
}
.scan-timing-table-coll-input .field .ui.input  {
    margin: 0 !important;
}

.scan-timing-table-coll-button button {
    padding: 0 !important;
    width: 16px !important;
    margin: 0 auto;
    height: 24px !important;
    border-top: 1px solid lightgrey !important;
    border-bottom: 1px solid grey !important;
    border-left: 1px solid grey !important;
    border-right: 1px solid grey !important;
    background: linear-gradient(to top, #b1bbc0 1%, #dfe5e7 100%) !important;
    cursor: default !important;
}

.scan-timing-table-coll-button button:hover {
    background: linear-gradient(to top,#dfe5e7 1%, #ebf1f3 100%) !important;
    border: 1px solid #2caeff !important;
}

.scan-timing-table-coll-button {
    width: 30px;
    align-self: flex-end;
    margin-bottom: 3px;
}

#tez-button {
    opacity: 0.5
}
#tez-button:hover {
    background: linear-gradient(to top, #b1bbc0 1%, #dfe5e7 100%) !important;
    border-top: 1px solid lightgrey !important;
    border-bottom: 1px solid grey !important;
    border-left: 1px solid grey !important;
    border-right: 1px solid grey !important;
}