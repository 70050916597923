.new-password-page {
    /* padding-top: 10rem; */
}

.new-password-block {
    width: 400px;
    margin: 0 auto;
    text-align: center;
    background: white;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.new-password-block > div {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.password-flex-item-1 {
    width: 100px;
    text-align: left;
    font-size: 0.9rem;
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
}

.new-password-block input {
    display: block;
    width: 250px;
    margin-bottom: 14px !important;
    height: 40px;
    font-size: 12px;
}

.new-password-block > button {
    width: 100px;
}

.new-password-block p {
    color: red;
    font-size: 12px;
}

.restore-submit { 
    display: block !important;
    width: 100px;
    margin: 14px auto 0 auto !important;
}

.new-password-page .message {
    width: 400px;
    margin: 5px auto 0 auto;
    padding: 5px 10px;
}

.new-password-page .message .header {
    margin-bottom: 10px;
}