
.scaning-range-table-row {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0;
    padding: 0;
    color: #1b4049 !important;
}

.scaning-range-table {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    height: 100px;
    width: 450px;
    flex-wrap: wrap;
}
.scaning-range-table-coll {
    width: 50px;
    font-size: 12px;
    align-self: flex-end;
    margin-bottom: 5px;
}

.scaning-range-table-coll-input-label {
    width: 100px !important;
    text-align: right;
}

.scaning-range-table-coll-input .field {
    font-size: 12px;
    width: 60px;
    margin: 0 !important;
}

.scaning-range-table-coll-input input {
    padding-left: 5px !important;
    padding-right: 1px !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    font-size: 10px;
}

.scaning-range-table-coll-button button {
    padding: 0 !important;
    width: 16px;
    display: block;
    margin: 0 auto;
    height: 24px;
    border-top: 1px solid lightgrey !important;
    border-bottom: 1px solid grey !important;
    border-left: 1px solid grey !important;
    border-right: 1px solid grey !important;
    background: linear-gradient(to top, #b1bbc0 1%, #dfe5e7 100%) !important;
    cursor: default !important;
}

.scaning-range-table-coll-button button:hover {
    background: linear-gradient(to top,#dfe5e7 1%, #ebf1f3 100%) !important;
    border: 1px solid #2caeff !important;
}

.scaning-range-table-coll-button {
    width: 20px;
    align-self: flex-end;
    margin-bottom: 3px;
}

.min-max-fov-field {
    width: 50px !important;
    text-align: center !important;
}