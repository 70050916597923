#graphicRX {
    width: 100%;
    height: 100%;
}

.plan-slices {
    display: contents;
}

.padding-ctrl {
    padding: 0 !important;
    width: 12% !important;
}

.select-padding-ctrl {
    padding: 0 !important;
    width: 32% !important;
    font-size: 0.8em !important;
}

