.autoview-zoom {
    display: inline;
    position: absolute;
    top: 0;
    right: 12px;
}

.autoview-zoom-button img {
    padding-top: 1px;
    padding-right: 1px;
    width: 12px;
    height: 12px;
}

.autoview-zoom-button button:disabled img {
    filter: opacity(50%);
    -webkit-filter: opacity(50%);
}

.autoview-zoom-button button {
    width: 15px !important;
    height: 15px !important;
    padding: 0 !important;
    border-top: 1px solid lightgrey !important;
    border-bottom: 1px solid grey !important;
    border-left: 1px solid grey !important;
    border-right: 1px solid grey !important;
    background: rgba(255,255,255,0.8) !important;
}

.autoview-zoom-button button:hover {
    border: 1px solid #2caeff !important;
    cursor: pointer;
}

.autoview-zoom-button button:hover:disabled {
    border-top: 1px solid lightgrey !important;
    border-bottom: 1px solid grey !important;
    border-left: 1px solid grey !important;
    border-right: 1px solid grey !important;
    cursor: default;
}


.autoview-zoom-button button .icon {
    margin: 0 !important;
}

.scaled-autoview-modal {
    width: 875px !important;
    height: 565px !important;
    background: #e2e2e2 !important;
    padding: 0px 10px 10px 10px !important;
    
}

.scaled-autoview-modal .header {
    padding: 0 !important;
    border-bottom: none !important;
    font-size: 12px !important;
    color: #1b4049 !important;
    background: #e2e2e2 !important;
    margin-bottom: 5px !important;
}

.scaled-autoview-modal .content {
    padding: 0 !important;
    padding: 3px !important;
    height: 535px !important;
    background: white !important;
} 

.scaled-autoview-modal .close.icon {
    padding: 3px 0 0 0 !important;
    margin: 0 !important;
    font-size: 14px !important;
}

.scaled-autoview-modal .close.icon:hover {
    cursor: default !important;
}

.scaled-autoview-modal .slider-container {
    background: black !important;
}

.scaled-autoview.autoview-button-block {
    width: 280px !important;
    margin: 5px auto;
}

.scaled-autoview-modal .carousel .carousel__slider {
    width: 500px !important;
    height: 500px !important;
    margin: 0 auto !important;
    background: black !important;
}
  
.scaled-autoview-modal .carousel .carousel__slider-tray-wrapper {
    width: 500px !important;
    height: 500px !important;
}

.scaled-autoview-modal .carousel .carousel__slider img {
    height: 500px !important;
}


.zoomed-slide-white-overlay {
    position: absolute;
    top: 0;
    width: 500px;
    height: 500px;
    background:white;
    filter: opacity(0);
    -webkit-filter: opacity(0);
}