.acquisition-timing {
    height: 155px !important;
    width: 255px;
    color: #1b4049 !important;
}


.acquisition-timing-table-row {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.acquisition-timing-table {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    height: 130px !important;
    flex-wrap: wrap;
    width: 255px;
    padding-left: 15px; 
}
.acquisition-timing-table-coll {
    font-size: 12px;
    align-self: flex-end;
}

.acquisition-timing-table-coll-input-label {
    width: 30px !important;
    text-align: right;
}

.acquisition-timing-table-coll .ui.input {
    width: 50px !important;
}
.acquisition-timing-table-coll-input .field {
    font-size: 12px;
    width: 60px;
    margin: 0 !important;
}

.acquisition-timing-table-coll-input input {
    padding-left: 5px !important;
    padding-right: 1px !important;
    font-size: 10px;
}

.acquisition-timing-table-coll-button button {
    padding: 0 !important;
    width: 16px !important;
    display: block;
    margin: 0 auto;
    height: 24px;
    border-top: 1px solid lightgrey !important;
    border-bottom: 1px solid grey !important;
    border-left: 1px solid grey !important;
    border-right: 1px solid grey !important;
    background: linear-gradient(to top, #b1bbc0 1%, #dfe5e7 100%) !important;
    cursor: default !important;
}

.acquisition-timing-table-coll-button button:hover {
    background: linear-gradient(to top,#dfe5e7 1%, #ebf1f3 100%) !important;
    cursor: default !important;
    border: 1px solid #2caeff !important;
}

.acquisition-timing-table-coll-button {
    width: 20px;
}