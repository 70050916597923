.form-grid {
  height: 100vh;
  overflow-y: scroll;
}


.register-form-grid-column, .login-form-grid-column {
  padding-top: 2% !important;
}

.register-form-grid-column, .login-form-grid-column {
  max-width: 450px;
}

.register-form-grid-column .ui.center.aligned.header {
  font-size: 2rem !important;
  font-weight: 700 !important;
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif !important;
  color: black !important;
  text-transform: none !important;
  margin: 2rem 0 1rem 0 !important;
}

.login-form-grid-column .ui.center.aligned.header{
  font-size: 2rem !important;
  font-weight: 700 !important;
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif !important;
  color: black !important;
  text-transform: none !important;
  margin: 1rem 0 1rem 0 !important;
}

.register-form-grid-column .field-error {
  margin-top: 0 !important;
}

.register-form-grid-column .ui.form .field {
  margin-top: 0.5rem !important;
  margin-bottom: 3rem !important;
  display: block !important;
}

.register-form-grid-column .field > label {
  margin-bottom: 0.5rem !important;
}

.register-form-grid-column .field > .selection.dropdown {
  height: 38px !important;
  width: 100% !important;
  max-width: 100% !important;
  clear: both !important;
  margin-left: 0 !important;
  line-height: 15px !important;
}

.register-form-grid-column .field > div input {
  height: 38px !important;
  width: 100% !important;
  clear: both !important;
}

.login-form.ui.form .field {
  margin-bottom: 14px !important;
}

.register-form-grid-column button {
  color: #0B333C !important
}

.register-form-grid-column .ui.message {
  font-size: 14px !important;
}

.restore {
  margin-top: 0 !important;
}

.sign-up {
  margin-bottom: 0 !important;
}

.error-style .rrt-title {  
  font-size: 18px !important;
}

.error-style .rrt-text {
  padding-top: 5px;
  font-size: 12px;
  line-height: 1.2;
}