.guide-container {
    position: absolute;
}

.tooltip-guide-box {
    user-select: none;
    position: relative;
    width: fit-content;
    padding: 5px 5px;
    font-size: 11px;
    background: #f8f9c9;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    box-shadow: 0 3px 3px rgba(0,0,0,0.5);
    z-index: 10;
}


.tooltip-guide-patient-info {
    left: 350px;
    top: 15px;
}

.tooltip-guide-patient-info-full {
    left: 350px;
    top: 45px;
}

.tooltip-guide-save-series {
    left: -150px;
    top: 550px;
}

.tooltip-guide-prepare-scan {
    left: -150px;
    top: 550px;
}

.tooltip-guide-scan-button {
    left: 500px;
    top: 580px;
}